// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const postCompanyTagsErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.POST_COMPANY_TAGS_ERROR> => ({
    type: CompaniesActionTypes.POST_COMPANY_TAGS_ERROR,
    payload: error,
});
