import {IGetUsersParams} from 'redux/actions/users/getUsersRequestAction';
import {PatchUserParams} from 'redux/actions/users/patchUserRequestAction';
import {request, Methods, withToken, RequestOptions} from 'utils/request';
import {UserDataType} from 'types/settings-type';

export const getUserRolesRequest = async () => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/cms/cms-users/roles`, options);
};

export const getUsersRequest = async (params: IGetUsersParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/admin/users`, options);
};

export const postUserRequest = async (data: UserDataType) => {
    const options: RequestOptions = await withToken({
        method: Methods.POST,
        data,
    });

    return request('/cms/admin/users', options);
};

export const patchUserRequest = async ({userId, ...data}: PatchUserParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(`/cms/admin/users/${userId}`, options);
};

export const deleteUserRequest = async (userId: number) => {
    const options: RequestOptions = await withToken({
        method: Methods.DELETE,
    });

    return request(`/cms/admin/users/${userId}`, options);
};
