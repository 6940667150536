import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';

export interface IDeleteTransactionsSuccessAction
    extends Action<TransactionsActionTypes.DELETE_TRANSACTIONS_SUCCESS> {
    payload: any;
}

export const deleteTransactionsSuccessAction =
    (): IDeleteTransactionsSuccessAction => ({
        type: TransactionsActionTypes.DELETE_TRANSACTIONS_SUCCESS,
        payload: null,
    });
