import {Action} from 'redux';
// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {ICompanyDocuments} from 'types/company-types';

export interface IGetCompanyDocumentsSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_DOCUMENTS_SUCCESS> {
    payload: ICompanyDocuments;
}

export const getCompanyDocumentsSuccessAction = (
    data: ICompanyDocuments
): IGetCompanyDocumentsSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_DOCUMENTS_SUCCESS,
    payload: data,
});
