import {Reducer} from 'redux';

// custom
import {IBanksState} from 'types/bank-types';
import {BanksActions} from 'redux/actions/banks';
import {BanksActionTypes} from 'redux/action-types/banks';
import {AuthActionTypes} from 'redux/action-types/auth';

const banksInitialState: IBanksState = {
  data: [],
  error: null,
  loading: false,
  loadingUpdate: false,
  pagination: {
    page: 1,
    perPage: 15,
    total: 0,
  },
};

export const BanksReducer: Reducer<IBanksState, BanksActions> = (
  state = banksInitialState,
  action
) => {
  switch (action.type) {
    case BanksActionTypes.GET_BANKS_BY_COUNTRY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case BanksActionTypes.GET_BANKS_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: {...state.pagination, ...action.payload.pagination},
        error: null,
        loading: false,
      };
    case BanksActionTypes.GET_BANKS_BY_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case BanksActionTypes.POST_BANK_BY_COUNTRY_REQUEST:
      return {
        ...state,
        error: null,
        loadingUpdate: true,
      };
    case BanksActionTypes.POST_BANK_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        error: null,
        loadingUpdate: false,
      };
    case BanksActionTypes.POST_BANK_BY_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingUpdate: false,
      };
    case BanksActionTypes.PATCH_BANK_BY_COUNTRY_REQUEST:
    case BanksActionTypes.DELETE_BANK_BY_COUNTRY_REQUEST:
      return {
        ...state,
        error: null,
        loadingUpdate: true,
      };
    case BanksActionTypes.PATCH_BANK_BY_COUNTRY_SUCCESS:
    case BanksActionTypes.DELETE_BANK_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        loadingUpdate: false,
      };
    case BanksActionTypes.PATCH_BANK_BY_COUNTRY_ERROR:
    case BanksActionTypes.DELETE_BANK_BY_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingUpdate: false,
      };
    case AuthActionTypes.LOGOUT:
      return banksInitialState;
    default:
      return state;
  }
};
