import { Action } from 'redux';
// custom
import { CountriesActionTypes } from 'redux/action-types/countries';
import { RequestError } from 'types';

// types
import { ICountriesData } from './getCountriesSuccesstAction';

export interface IGetCountriesParams {
    page: number;
    perPage?: number;
    debugMode?: boolean;
}

export interface IGetCountriesRequestAction
    extends Action<CountriesActionTypes.GET_COUNTRIES_REQUEST> {
    payload: IGetCountriesParams;
    callback: (error?: RequestError | any, data?: ICountriesData) => void;
}

export const getCountriesRequestAction = (
    params: IGetCountriesParams,
    callback: (error?: RequestError | any, data?: ICountriesData) => void
): IGetCountriesRequestAction => ({
    type: CountriesActionTypes.GET_COUNTRIES_REQUEST,
    payload: {perPage: 15, ...params},
    callback,
});
