import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IGetCompaniesTransactionsReportSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_SUCCESS> {
    payload: any;
}

export const getCompaniesTransactionsReportSuccessAction = (
    data: any
): IGetCompaniesTransactionsReportSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_SUCCESS,
    payload: data,
});
