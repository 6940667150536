// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {RequestError, ReduxError} from 'types';

export const getUserRolesErrorAction = (
    error: RequestError
): ReduxError<UsersActionTypes.GET_USER_ROLES_ERROR> => ({
    type: UsersActionTypes.GET_USER_ROLES_ERROR,
    payload: error,
});
