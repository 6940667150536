export enum DepositsActionTypes {
    GET_DEPOSITS_BY_COUNTRY_REQUEST = 'GET_DEPOSITS_BY_COUNTRY_REQUEST',
    GET_DEPOSITS_BY_COUNTRY_SUCCESS = 'GET_DEPOSITS_BY_COUNTRY_SUCCESS',
    GET_DEPOSITS_BY_COUNTRY_ERROR = 'GET_DEPOSITS_BY_COUNTRY_ERROR',
    GET_DEPOSIT_DETAIL_REQUEST = 'GET_DEPOSIT_DETAIL_REQUEST',
    GET_DEPOSIT_DETAIL_SUCCESS = 'GET_DEPOSIT_DETAIL_SUCCESS',
    GET_DEPOSIT_DETAIL_ERROR = 'GET_DEPOSIT_DETAIL_ERROR',
    POST_DEPOSIT_BY_COUNTRY_REQUEST = 'POST_DEPOSIT_BY_COUNTRY_REQUEST',
    POST_DEPOSIT_BY_COUNTRY_SUCCESS = 'POST_DEPOSIT_BY_COUNTRY_SUCCESS',
    POST_DEPOSIT_BY_COUNTRY_ERROR = 'POST_DEPOSIT_BY_COUNTRY_ERROR',
    PATCH_DEPOSIT_REQUEST = 'PATCH_DEPOSIT_REQUEST',
    PATCH_DEPOSIT_SUCCESS = 'PATCH_DEPOSIT_SUCCESS',
    PATCH_DEPOSIT_ERROR = 'PATCH_DEPOSIT_ERROR',
}
