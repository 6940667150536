import {Reducer} from 'redux';

// custom
import {ICategoriesState} from 'types/category-types';
import {CategoriesActions} from 'redux/actions/categories';
import {CategoriesActionTypes} from 'redux/action-types/categories';
import {AuthActionTypes} from 'redux/action-types/auth';

const categoriesInitialState: ICategoriesState = {
    data: [],
    error: null,
    loading: false,
};

export const CategoriesReducer: Reducer<ICategoriesState, CategoriesActions> = (
    state = categoriesInitialState,
    action
) => {
    switch (action.type) {
        case CategoriesActionTypes.GET_CATEGORIES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case CategoriesActionTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                loading: false,
            };
        case CategoriesActionTypes.GET_CATEGORIES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case AuthActionTypes.LOGOUT:
            return categoriesInitialState;
        default:
            return state;
    }
};
