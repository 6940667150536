// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const deleteCompanyKYCErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.DELETE_COMPANY_KYC_ERROR> => ({
    type: CompaniesActionTypes.DELETE_COMPANY_KYC_ERROR,
    payload: error,
});
