// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {ReduxError, RequestError} from 'types';

export const getCompanyDocumentsErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.GET_COMPANY_DOCUMENTS_ERROR> => ({
    type: CompaniesActionTypes.GET_COMPANY_DOCUMENTS_ERROR,
    payload: error,
});
