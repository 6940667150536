import {connect} from 'react-redux';

// screen
import {HeaderNav} from './HeaderNav';

// selectors
import {isNavCollapse, getLocale} from 'redux/selectors/theme';
import {getCurrentUser} from 'redux/selectors/auth';
import {
    getCountriesData,
    getCurrentCountry,
    isLoadingCountries,
} from 'redux/selectors/countries';

// types
import {IStoreState} from 'types';

const mapStateToProps = (state: IStoreState) => ({
    navCollapsed: isNavCollapse(state),
    locale: getLocale(state),
    currentUser: getCurrentUser(state),
    countries: getCountriesData(state),
    currentCountry: getCurrentCountry(state),
    loadingCountries: isLoadingCountries(state),
});

export default connect(mapStateToProps)(HeaderNav);
