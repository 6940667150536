import {createSelector} from 'reselect';
// custom
import {IStoreState} from 'types';
import {ICountriesState} from 'types/country-types';

export const countries = (state: IStoreState) => state.countries;

export const getCountriesData = createSelector(
    countries,
    (state: ICountriesState) => state.data
);

export const isLoadingCountries = createSelector(
    countries,
    (state: ICountriesState) => state.loading
);

export const isLoadingUpdateCountries = createSelector(
    countries,
    (state: ICountriesState) => state.loadingUpdate
);

export const getPage = createSelector(
    countries,
    (state: ICountriesState) => state.pagination.page
);

export const getTotalCountries = createSelector(
    countries,
    (state: ICountriesState) => state.pagination.total
);

export const getCurrentLimit = createSelector(
    countries,
    (state: ICountriesState) => state.pagination.perPage
);
export const getCurrentCountry = createSelector(
    countries,
    (state: ICountriesState) => state.currentCountry
);

export const getCurrentCountryIsoName = createSelector(
    countries,
    (state: ICountriesState) => {
        const currentCountryId = state.currentCountry;
        const country = state.data.find(
            (country) => country.id === currentCountryId
        );
        return country?.iso2 || '';
    }
);
