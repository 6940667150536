// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const getCompaniesErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.GET_COMPANIES_ERROR> => ({
    type: CompaniesActionTypes.GET_COMPANIES_ERROR,
    payload: error,
});
