import {Action} from 'redux';

// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {IUserRole} from 'types/user-types';

export interface IUserRoleData {
    data: IUserRole[];
}

export interface IGetUserRolesSuccessAction
    extends Action<UsersActionTypes.GET_USER_ROLES_SUCCESS> {
    payload: IUserRoleData;
}

export const getUserRolesSuccessAction = (
    data: IUserRoleData
): IGetUserRolesSuccessAction => ({
    type: UsersActionTypes.GET_USER_ROLES_SUCCESS,
    payload: data,
});
