import {Action} from 'redux';

// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';
import {IPagination} from 'types';
import {IDepositDetail} from 'types/deposits-types';

export interface IDepositDetailData {
  data: IDepositDetail[];
  pagination: IPagination;
}

export interface IGetDepositDetailSuccessAction
  extends Action<DepositsActionTypes.GET_DEPOSIT_DETAIL_SUCCESS> {
  payload: IDepositDetailData;
}

export const getDepositDetailSuccessAction = (
  data: IDepositDetailData
): IGetDepositDetailSuccessAction => ({
  type: DepositsActionTypes.GET_DEPOSIT_DETAIL_SUCCESS,
  payload: data,
});
