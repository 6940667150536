import {Action} from 'redux';

// custom
import {CountriesActionTypes} from 'redux/action-types/countries';

export interface ISetCurrentCountryAction
    extends Action<CountriesActionTypes.SET_CURRENT_COUNTRY> {
    payload: number;
}

export const setCurrentCountryAction = (
    countryId: number
): ISetCurrentCountryAction => ({
    type: CountriesActionTypes.SET_CURRENT_COUNTRY,
    payload: countryId,
});
