import {request, Methods, withToken, RequestOptions} from 'utils/request';

export const getCategoriesRequest = async () => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        // params,
    });

    return request('/categories', options);
};
