import {IGetBanksByCountryParams} from 'redux/actions/banks/getBanksByCountryRequestAction';
import {IBankFormFields} from 'components/banks/BankForm';
import {PatchBankByCountryParams} from 'redux/actions/banks/patchBankByCountryRequestAction';

import {request, Methods, withToken, RequestOptions} from 'utils/request';

export const getBanksByCountryRequest = async ({
  countryId,
  ...params
}: IGetBanksByCountryParams) => {
  const options: RequestOptions = await withToken({
    method: Methods.GET,
    params,
  });

  return request(`/countries/${countryId}/banks`, options);
};

export const postBankByCountryRequest = async (data: IBankFormFields) => {
  const options: RequestOptions = await withToken({
    method: Methods.POST,
    data,
  });

  return request('/cms/banks', options);
};

export const patchBankByCountryRequest = async ({
  id,
  ...data
}: PatchBankByCountryParams) => {
  const options: RequestOptions = await withToken({
    method: Methods.PATCH,
    data,
  });

  return request(`/cms/banks/${id}`, options);
};

export const deleteBankByCountryRequest = async (id: number) => {
  const options: RequestOptions = await withToken({
    method: Methods.DELETE,
  });

  return request(`/cms/banks/${id}`, options);
};
