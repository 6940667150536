// custom
import {BanksActionTypes} from 'redux/action-types/banks';
import {RequestError, ReduxError} from 'types';

export const patchBankByCountryErrorAction = (
  error: RequestError
): ReduxError<BanksActionTypes.PATCH_BANK_BY_COUNTRY_ERROR> => ({
  type: BanksActionTypes.PATCH_BANK_BY_COUNTRY_ERROR,
  payload: error,
});
