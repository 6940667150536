import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IPatchCompanyPOSStatusSuccessAction
    extends Action<CompaniesActionTypes.PATCH_COMPANY_POS_STATUS_SUCCESS> {
    payload: any;
}

export const patchCompanyPOSStatusSuccessAction = (
    data: any
): IPatchCompanyPOSStatusSuccessAction => ({
    type: CompaniesActionTypes.PATCH_COMPANY_POS_STATUS_SUCCESS,
    payload: data,
});
