// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';
import {RequestError, ReduxError} from 'types';

export const getDepositDetailErrorAction = (
  error: RequestError
): ReduxError<DepositsActionTypes.GET_DEPOSIT_DETAIL_ERROR> => ({
  type: DepositsActionTypes.GET_DEPOSIT_DETAIL_ERROR,
  payload: error,
});
