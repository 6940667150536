import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {ICompanyRelatedCompanies} from 'types/company-types';

export interface IGetRelatedCompaniesSuccessAction
    extends Action<CompaniesActionTypes.GET_RELATED_COMPANIES_SUCCESS> {
    payload: ICompanyRelatedCompanies[];
}

export const getRelatedCompaniesSuccessAction = (
    data: ICompanyRelatedCompanies[]
): IGetRelatedCompaniesSuccessAction => ({
    type: CompaniesActionTypes.GET_RELATED_COMPANIES_SUCCESS,
    payload: data,
});
