import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';

export interface IAuthData {
  token: string;
  refresh: string;
}

export interface IPostLoginSuccessAction
  extends Action<AuthActionTypes.POST_LOGIN_SUCCESS> {
  payload: IAuthData;
}

export const postLoginSuccessAction = (
  data: IAuthData
): IPostLoginSuccessAction => ({
  type: AuthActionTypes.POST_LOGIN_SUCCESS,
  payload: data,
});
