import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {IAuthData} from './postLoginSuccessAction';

export interface IPostRefreshTokenSuccessAction
  extends Action<AuthActionTypes.POST_REFRESH_TOKEN_SUCCESS> {
  payload: IAuthData;
}

export const postRefreshTokenSuccessAction = (
  data: IAuthData
): IPostRefreshTokenSuccessAction => ({
  type: AuthActionTypes.POST_REFRESH_TOKEN_SUCCESS,
  payload: data,
});
