// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const getCompanyErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.GET_COMPANY_ERROR> => ({
    type: CompaniesActionTypes.GET_COMPANY_ERROR,
    payload: error,
});
