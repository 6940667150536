export enum PermissionEnum {
    None = 'none',
    Read = 'read',
    Update = 'update',
    Admin = 'admin',
}

export const UserPermissions = {
    [PermissionEnum.None]: 'Ninguno',
    [PermissionEnum.Read]: 'Ver',
    [PermissionEnum.Update]: 'Editar',
    [PermissionEnum.Admin]: 'Admin',
} as const;

export interface ICountryPermissions {
    countryId: number;
    permissions: UserPermissionsType;
}

export interface UserPermissionsType {
    deposits: PermissionEnum;
    companies: PermissionEnum;
    reports: PermissionEnum;
    transactions: PermissionEnum;
    settings: PermissionEnum;
    emv: PermissionEnum;
    contact_center: PermissionEnum;
    plans: PermissionEnum;
}
export interface UserDataType {
    firstName: string;
    lastName: string;
    email: string;
    userRoleId: number | string;
    countryPermissions: ICountryPermissions[];
}
