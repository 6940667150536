import {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

const Reports = lazy(() => import('pages/dashboard/reports'));

const ReportsRoute = () => {
    return (
        <Switch>
            <Route exact path="/reports" component={Reports} />
        </Switch>
    );
};

export default ReportsRoute;
