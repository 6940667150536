export enum BanksActionTypes {
  GET_BANKS_BY_COUNTRY_REQUEST = 'GET_BANKS_BY_COUNTRY_REQUEST',
  GET_BANKS_BY_COUNTRY_SUCCESS = 'GET_BANKS_BY_COUNTRY_SUCCESS',
  GET_BANKS_BY_COUNTRY_ERROR = 'GET_BANKS_BY_COUNTRY_ERROR',
  POST_BANK_BY_COUNTRY_REQUEST = 'POST_BANKS_BY_COUNTRY_REQUEST',
  POST_BANK_BY_COUNTRY_SUCCESS = 'POST_BANKS_BY_COUNTRY_SUCCESS',
  POST_BANK_BY_COUNTRY_ERROR = 'POST_BANKS_BY_COUNTRY_ERROR',
  PATCH_BANK_BY_COUNTRY_REQUEST = 'PATCH_BANK_BY_COUNTRY_REQUEST',
  PATCH_BANK_BY_COUNTRY_SUCCESS = 'PATCH_BANK_BY_COUNTRY_SUCCESS',
  PATCH_BANK_BY_COUNTRY_ERROR = 'PATCH_BANK_BY_COUNTRY_ERROR',
  DELETE_BANK_BY_COUNTRY_REQUEST = 'DELETE_BANK_BY_COUNTRY_REQUEST',
  DELETE_BANK_BY_COUNTRY_SUCCESS = 'DELETE_BANK_BY_COUNTRY_SUCCESS',
  DELETE_BANK_BY_COUNTRY_ERROR = 'DELETE_BANK_BY_COUNTRY_ERROR',
}
