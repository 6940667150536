import {Action} from 'redux';
// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {IIntentData} from 'types/transactions-type';

export interface IGetTransactionIntentsSuccessAction
    extends Action<TransactionsActionTypes.GET_TRANSACTION_INTENTS_SUCCESS> {
    payload: IIntentData[];
}

export const getTransactionIntentsSuccessAction = (
    data: IIntentData[]
): IGetTransactionIntentsSuccessAction => ({
    type: TransactionsActionTypes.GET_TRANSACTION_INTENTS_SUCCESS,
    payload: data,
});
