// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const postCompanyNoteErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.POST_COMPANY_NOTE_ERROR> => ({
    type: CompaniesActionTypes.POST_COMPANY_NOTE_ERROR,
    payload: error,
});
