import {Route, Redirect, RouteProps} from 'react-router-dom';

type PublicRouteProps = {
    isAuthenticated: boolean;
    component: Function;
    computedMatch?: {
        isExact: boolean;
        params: any; // {}
        path: string;
        url: string;
    };
    location?: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: any;
    };
    path?: string;
};

export const PublicRoute: React.FC<PublicRouteProps> = ({
    isAuthenticated,
    component: Component,
    ...props
}) => {
    return (
        <Route
            {...props}
            component={(props: RouteProps) =>
                !isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};
