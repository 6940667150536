import {AxiosResponse} from 'axios';
import get from 'lodash/get';
import {call, put, select, takeLatest} from 'redux-saga/effects';
// action types
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {deleteTransactionsErrorAction} from 'redux/actions/transactions/deleteTransactionsErrorAction';
// deleteCompanyKYC
import {IDeleteTransactionsRequestAction} from 'redux/actions/transactions/deleteTransactionsRequestAction';
import {deleteTransactionsSuccessAction} from 'redux/actions/transactions/deleteTransactionsSuccessAction';
import {getAnalyticsErrorAction} from 'redux/actions/transactions/getAnalyticsErrorAction';
// Analytics
import {IGetAnalyticsRequestAction} from 'redux/actions/transactions/getAnalyticsRequestAction';
// actions
// Analytics
import {
    getAnalyticsSuccessAction,
    IAnalyticsData,
} from 'redux/actions/transactions/getAnalyticsSuccesstAction';
import {getIntentErrorAction} from 'redux/actions/transactions/getIntentErrorAction';
import {getIntentNotesErrorAction} from 'redux/actions/transactions/getIntentNotesErrorAction';
// getIntentNotes
import {IGetIntentNotesRequestAction} from 'redux/actions/transactions/getIntentNotesRequestAction';
import {
    getIntentNotesSuccessAction,
    IIntentNotesData,
} from 'redux/actions/transactions/getIntentNotesSuccesstAction';
// getIntent
import {IGetIntentRequestAction} from 'redux/actions/transactions/getIntentRequestAction';
import {getIntentSuccessAction} from 'redux/actions/transactions/getIntentSuccesstAction';
import {getTransactionIntentsErrorAction} from 'redux/actions/transactions/getTransactionIntentsErrorAction';
// Transaction Intents
import {IGetTransactionIntentsRequestAction} from 'redux/actions/transactions/getTransactionIntentsRequestAction';
import {getTransactionIntentsSuccessAction} from 'redux/actions/transactions/getTransactionIntentsSuccesstAction';
import {getTransactionsErrorAction} from 'redux/actions/transactions/getTransactionsErrorAction';
// Transactions
import {IGetTransactionsRequestAction} from 'redux/actions/transactions/getTransactionsRequestAction';
// Transactions
import {
    getTransactionsSuccessAction,
    ITransactionsData,
} from 'redux/actions/transactions/getTransactionsSuccesstAction';
import {patchTransactionDepositStatusErrorAction} from 'redux/actions/transactions/patchTransactionDepositStatusErrorAction';
// patchTransactionDepositStatus
import {IPatchTransactionDepositStatusRequestAction} from 'redux/actions/transactions/patchTransactionDepositStatusRequestAction';
import {patchTransactionDepositStatusSuccessAction} from 'redux/actions/transactions/patchTransactionDepositStatusSuccessAction';
import {postIntentNoteErrorAction} from 'redux/actions/transactions/postIntentNoteErrorAction';
// postIntentNote
import {IPostIntentNoteRequestAction} from 'redux/actions/transactions/postIntentNoteRequestAction';
import {postIntentNoteSuccessAction} from 'redux/actions/transactions/postIntentNoteSuccessAction';
// requests
import {
    deleteTransactionsRequest,
    getAnalyticsRequest,
    getIntentNotesRequest,
    getIntentRequest,
    getTransactionIntentsRequest,
    getTransactionsRequest,
    patchTransactionDepositStatusRequest,
    postIntentNoteRequest,
} from 'services/transactions';
// types
import {IPagination, IStoreState, RequestError} from 'types';
import {IIntentData, TransactionDepositStatus} from 'types/transactions-type';
// custom
import {parseErrors} from 'utils';

function* getAnalytics(action: IGetAnalyticsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getAnalyticsRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: IAnalyticsData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getAnalyticsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getAnalyticsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getTransactions(action: IGetTransactionsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getTransactionsRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: ITransactionsData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getTransactionsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getTransactionsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getTransactionIntents(action: IGetTransactionIntentsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getTransactionIntentsRequest,
            action.payload
        );

        const data: IIntentData[] = get(result.data, 'data', []);

        yield put(getTransactionIntentsSuccessAction(data));

        action.callback && action.callback(null, result.data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getTransactionIntentsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getIntent(action: IGetIntentRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getIntentRequest,
            action.payload
        );

        yield put(getIntentSuccessAction(get(result.data, 'data', {})));

        action.callback && action.callback(null, result.data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getIntentErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getIntentNotes(action: IGetIntentNotesRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getIntentNotesRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: IIntentNotesData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getIntentNotesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getIntentNotesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postIntentNote(action: IPostIntentNoteRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postIntentNoteRequest,
            action.payload
        );

        // const data: IIntentNotes = get(result.data, 'data', {});
        const data = get(result.data, 'data', {});

        yield put(postIntentNoteSuccessAction());

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postIntentNoteErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchTransactionDepositStatus(
    action: IPatchTransactionDepositStatusRequestAction
) {
    const {transactions}: IStoreState = yield select();

    const {
        intentDetail: {data},
    } = transactions;

    const {transactionId} = data || {};

    try {
        const result: AxiosResponse = yield call(
            patchTransactionDepositStatusRequest,
            action.payload
        );

        const response: {
            id: number;
            riskStatus: TransactionDepositStatus;
        } = get(result.data, 'data', {});

        if (transactions?.data?.length > 0) {
            const transactionIndex = transactions.data.findIndex(
                (trx) => trx.transactionId === transactionId
            );

            transactions.data[transactionIndex].intentData!.riskStatus =
                response.riskStatus;
        }

        const intentData = {
            ...data,
            intentData: {
                ...data!.intentData,
                riskStatus: response.riskStatus,
            },
        };

        yield put(patchTransactionDepositStatusSuccessAction(intentData));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchTransactionDepositStatusErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* deleteTransactions(action: IDeleteTransactionsRequestAction) {
    try {
        yield call(deleteTransactionsRequest, action.payload);

        yield put(deleteTransactionsSuccessAction());

        action.callback && action.callback(null, null);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(deleteTransactionsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* transactionsSagas() {
    yield takeLatest(
        TransactionsActionTypes.GET_ANALYTICS_REQUEST,
        getAnalytics
    );
    yield takeLatest(
        TransactionsActionTypes.GET_TRANSACTIONS_REQUEST,
        getTransactions
    );
    yield takeLatest(
        TransactionsActionTypes.GET_TRANSACTION_INTENTS_REQUEST,
        getTransactionIntents
    );
    yield takeLatest(TransactionsActionTypes.GET_INTENT_REQUEST, getIntent);
    yield takeLatest(
        TransactionsActionTypes.GET_INTENT_NOTES_REQUEST,
        getIntentNotes
    );
    yield takeLatest(
        TransactionsActionTypes.POST_INTENT_NOTE_REQUEST,
        postIntentNote
    );
    yield takeLatest(
        TransactionsActionTypes.PATCH_TRANSACTION_DEPOSIT_STATUS_REQUEST,
        patchTransactionDepositStatus
    );
    yield takeLatest(
        TransactionsActionTypes.DELETE_TRANSACTIONS_REQUEST,
        deleteTransactions
    );
}
