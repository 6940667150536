import {takeLatest, call, put, select} from 'redux-saga/effects';
import get from 'lodash/get';
import {AxiosResponse} from 'axios';

// custom
import {parseErrors} from 'utils';

// action types
import {DepositsActionTypes} from 'redux/action-types/deposits';

// requests
import {
    getDepositsByCountryRequest,
    getDepositDetailRequest,
    postDepositByCountryRequest,
    patchDepositItemRequest,
} from 'services/deposits';

// actions
import {
    IDepositsByCountryData,
    getDepositsByCountrySuccessAction,
} from 'redux/actions/deposits/getDepositsByCountrySuccessAction';
import {getDepositsByCountryErrorAction} from 'redux/actions/deposits/getDepositsByCountryErrorAction';
import {
    IDepositDetailData,
    getDepositDetailSuccessAction,
} from 'redux/actions/deposits/getDepositDetailSuccessAction';
import {getDepositDetailErrorAction} from 'redux/actions/deposits/getDepositDetailErrorAction';
import {postDepositByCountrySuccessAction} from 'redux/actions/deposits/postDepositByCountrySuccessAction';
import {postDepositByCountryErrorAction} from 'redux/actions/deposits/postDepositByCountryErrorAction';

// types
import {IGetDepositsByCountryRequestAction} from 'redux/actions/deposits/getDepositsByCountryRequestAction';
import {IGetDepositDetailRequestAction} from 'redux/actions/deposits/getDepositDetailRequestAction';
import {IPostDepositByCountryRequestAction} from 'redux/actions/deposits/postDepositByCountryRequestAction';
import {IPagination, RequestError, IStoreState} from 'types';
import {IDeposit, IDepositDetail} from 'types/deposits-types';

// Patch Deposit Item
import {IPatchDepositRequestAction} from 'redux/actions/deposits/patchDepositRequestAction';
import {patchDepositSuccessAction} from 'redux/actions/deposits/patchDepositSuccessAction';
import {patchDepositErrorAction} from 'redux/actions/deposits/patchDepositErrorAction';

function* getDepositsByCountry(action: IGetDepositsByCountryRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getDepositsByCountryRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: IDepositsByCountryData = {
            data: get(result.data, 'data', []),
            pagination: {
                page: pagination.page,
                total: pagination.total,
                totalPages: pagination.totalPages,
            },
        };

        yield put(getDepositsByCountrySuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getDepositsByCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getDepositDetail(action: IGetDepositDetailRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getDepositDetailRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: IDepositDetailData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getDepositDetailSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getDepositDetailErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postDepositByCountry(action: IPostDepositByCountryRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postDepositByCountryRequest,
            action.payload
        );
        const data: IDeposit = get(result.data, 'data', {});

        yield put(postDepositByCountrySuccessAction());

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postDepositByCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchDepositItem(action: IPatchDepositRequestAction) {
    const {
        deposits: {
            detail: {list: detailItemsList},
        },
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchDepositItemRequest,
            action.payload
        );
        const dataUpdated: IDepositDetail = get(result.data.data, 'data', {});
        const currentCountryIndex = detailItemsList.findIndex(
            (item: IDepositDetail) => item.identifier === dataUpdated.identifier
        );

        detailItemsList[currentCountryIndex] = dataUpdated;

        yield put(patchDepositSuccessAction(detailItemsList));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchDepositErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* depositsSagas() {
    yield takeLatest(
        DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_REQUEST,
        getDepositsByCountry
    );
    yield takeLatest(
        DepositsActionTypes.GET_DEPOSIT_DETAIL_REQUEST,
        getDepositDetail
    );
    yield takeLatest(
        DepositsActionTypes.POST_DEPOSIT_BY_COUNTRY_REQUEST,
        postDepositByCountry
    );
    yield takeLatest(
        DepositsActionTypes.PATCH_DEPOSIT_REQUEST,
        patchDepositItem
    );
}
