import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';

export interface IPatchTransactionDepositStatusSuccessAction
    extends Action<TransactionsActionTypes.PATCH_TRANSACTION_DEPOSIT_STATUS_SUCCESS> {
    payload: any;
}

export const patchTransactionDepositStatusSuccessAction = (
    data: any
): IPatchTransactionDepositStatusSuccessAction => ({
    type: TransactionsActionTypes.PATCH_TRANSACTION_DEPOSIT_STATUS_SUCCESS,
    payload: data,
});
