// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {RequestError, ReduxError} from 'types';

export const deleteUserErrorAction = (
    error: RequestError
): ReduxError<UsersActionTypes.DELETE_USER_ERROR> => ({
    type: UsersActionTypes.DELETE_USER_ERROR,
    payload: error,
});
