import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {ICompanyTag} from 'types/company-types';

export interface ICompanyTagsData {
    data: ICompanyTag[];
}

export interface IGetCompanyTagsSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_TAGS_SUCCESS> {
    payload: ICompanyTagsData;
}

export const getCompanyTagsSuccessAction = (
    data: ICompanyTagsData
): IGetCompanyTagsSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_TAGS_SUCCESS,
    payload: data,
});
