import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

// custom
import {App} from './App';
import {store, persistor} from 'redux/store';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import reportWebVitals from './reportWebVitals';

// styles
import './index.scss';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
