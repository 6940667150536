import {Action} from 'redux';

// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {IUser} from 'types/user-types';

export interface IDeleteUserSuccessAction
    extends Action<UsersActionTypes.DELETE_USER_SUCCESS> {
    payload: IUser[];
}

export const deleteUserSuccessAction = (
    data: IUser[]
): IDeleteUserSuccessAction => ({
    type: UsersActionTypes.DELETE_USER_SUCCESS,
    payload: data,
});
