import * as storage from 'localforage';
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
// actions
import {AuthActions} from 'redux/actions/auth';
import {CountriesActions} from 'redux/actions/countries';
import {ThemeActions} from 'redux/actions/theme';
// reducers
import {AuthReducer} from 'redux/reducers/auth';
import {BanksReducer} from 'redux/reducers/banks';
import {CategoriesReducer} from 'redux/reducers/categories';
import {CompaniesReducer} from 'redux/reducers/companies';
import {CountriesReducer} from 'redux/reducers/countries';
import {DepositsReducer} from 'redux/reducers/deposits';
import {ThemeReducer} from 'redux/reducers/theme';
import {TransactionsReducer} from 'redux/reducers/transactions';
// custom
import {IStoreState} from 'types';
// types
import {IAuthState} from 'types/auth-types';

import {TagsReducer} from './tags';
import {UsersReducer} from './users';

interface IPersistConfig {
    blacklist?: string[];
    version?: number;
    key: string;
    storage: any;
}

const AuthPersistConfig: IPersistConfig = {
    blacklist: ['loading', 'error', 'isRefreshing'],
    key: 'authentication',
    storage,
};

const ThemePersistConfig: IPersistConfig = {
    blacklist: [],
    key: 'theme',
    storage,
};

const CountryPersistConfig: IPersistConfig = {
    version: 0,
    key: 'countries',
    storage,
};

export const reducers = combineReducers<IStoreState>({
    auth: persistReducer<IAuthState, AuthActions>(
        AuthPersistConfig,
        AuthReducer
    ),
    theme: persistReducer<any, ThemeActions>(ThemePersistConfig, ThemeReducer),
    countries: persistReducer<any, CountriesActions>(
        CountryPersistConfig,
        CountriesReducer
    ),
    // countries: CountriesReducer,
    banks: BanksReducer,
    deposits: DepositsReducer,
    companies: CompaniesReducer,
    categories: CategoriesReducer,
    transactions: TransactionsReducer,
    tags: TagsReducer,
    users: UsersReducer,
});
