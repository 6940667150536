import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
// action types
import { UsersActionTypes } from 'redux/action-types/users';
import {
  deleteUserErrorAction,
} from 'redux/actions/users/deleteUserErrorAction';
// deleteTag
import {
  IDeleteUserRequestAction,
} from 'redux/actions/users/deleteUserRequestAction';
import {
  deleteUserSuccessAction,
} from 'redux/actions/users/deleteUserSuccessAction';
import {
  getUserRolesErrorAction,
} from 'redux/actions/users/getUserRolesErrorAction';
// getUserRoles
import {
  IGetUserRolesRequestAction,
} from 'redux/actions/users/getUserRolesRequestAction';
import {
  getUserRolesSuccessAction,
  IUserRoleData,
} from 'redux/actions/users/getUserRolesSuccesstAction';
import { getUsersErrorAction } from 'redux/actions/users/getUsersErrorAction';
// getUsers
import {
  IGetUsersRequestAction,
} from 'redux/actions/users/getUsersRequestAction';
import {
  getUsersSuccessAction,
  IUsersData,
} from 'redux/actions/users/getUsersSuccesstAction';
import { patchUserErrorAction } from 'redux/actions/users/patchUserErrorAction';
// patchTag
import {
  IPatchUserRequestAction,
} from 'redux/actions/users/patchUserRequestAction';
import {
  patchUserSuccessAction,
} from 'redux/actions/users/patchUserSuccessAction';
import { postUserErrorAction } from 'redux/actions/users/postUserErrorAction';
// postTag
import {
  IPostUserRequestAction,
} from 'redux/actions/users/postUserRequestAction';
import {
  postUserSuccessAction,
} from 'redux/actions/users/postUserSuccessAction';
// requests
import {
  deleteUserRequest,
  getUserRolesRequest,
  getUsersRequest,
  patchUserRequest,
  postUserRequest,
} from 'services/users';
import {
  IPagination,
  IStoreState,
  RequestError,
} from 'types';
import { IUser } from 'types/user-types';
// custom
import { parseErrors } from 'utils';

function* getUserRoles(action: IGetUserRolesRequestAction) {
    try {
        const result: AxiosResponse = yield call(getUserRolesRequest);

        const data: IUserRoleData = {
            data: get(result.data, 'data', []),
        };

        yield put(getUserRolesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getUserRolesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getUsers(action: IGetUsersRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getUsersRequest,
            action.payload
        );

        const pagination: IPagination = get(result.data, 'pagination', {});

        const data: IUsersData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getUsersSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getUsersErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postUser(action: IPostUserRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postUserRequest,
            action.payload
        );

        const data: IUser = get(result.data, 'data', {});

        const userListData = {
            ...data,
            countryPermissions: [...action.payload.countryPermissions],
        };

        yield put(postUserSuccessAction(userListData));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postUserErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchUser(action: IPatchUserRequestAction) {
    const {
        users: {data: userList},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchUserRequest,
            action.payload
        );
        const dataUpdated: IUser = get(result.data, 'data', {});

        const currentUserIndex = userList.findIndex(
            (item: IUser) => item.id === dataUpdated.id
        );

        userList[currentUserIndex] = {
            ...userList[currentUserIndex],
            ...dataUpdated,
            countryPermissions: [...action.payload.countryPermissions],
        };

        yield put(patchUserSuccessAction(userList));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchUserErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* deleteUser(action: IDeleteUserRequestAction) {
    const {
        users: {data: userList},
    }: IStoreState = yield select();

    try {
        yield call(deleteUserRequest, action.payload);

        const data: IUser[] = userList.filter(
            (item: IUser) => item.id !== action.payload
        );

        yield put(deleteUserSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(deleteUserErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* usersSagas() {
    yield takeLatest(UsersActionTypes.GET_USER_ROLES_REQUEST, getUserRoles);
    yield takeLatest(UsersActionTypes.GET_USERS_REQUEST, getUsers);
    yield takeLatest(UsersActionTypes.POST_USER_REQUEST, postUser);
    yield takeLatest(UsersActionTypes.PATCH_USER_REQUEST, patchUser);
    yield takeLatest(UsersActionTypes.DELETE_USER_REQUEST, deleteUser);
}
