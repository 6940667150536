// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {RequestError, ReduxError} from 'types';

export const deleteTransactionsErrorAction = (
    error: RequestError
): ReduxError<TransactionsActionTypes.DELETE_TRANSACTIONS_ERROR> => ({
    type: TransactionsActionTypes.DELETE_TRANSACTIONS_ERROR,
    payload: error,
});
