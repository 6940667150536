import {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

const Companies = lazy(() => import('pages/dashboard/companies'));
const CompanyDetail = lazy(() => import('pages/dashboard/companies/detail'));

const CompaniesRoutes = () => {
    return (
        <Switch>
            <Route exact path="/companies" component={Companies} />
            <Route
                exact
                path="/companies/:identifier"
                component={CompanyDetail}
            />
        </Switch>
    );
};

export default CompaniesRoutes;
