import {Action} from 'redux';

// custom
import {TagsActionTypes} from 'redux/action-types/tags';
import {ITag} from 'types/tag-types';

export interface IDeleteTagSuccessAction
    extends Action<TagsActionTypes.DELETE_TAG_SUCCESS> {
    payload: ITag[];
}

export const deleteTagSuccessAction = (
    data: ITag[]
): IDeleteTagSuccessAction => ({
    type: TagsActionTypes.DELETE_TAG_SUCCESS,
    payload: data,
});
