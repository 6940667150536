// custom
import {TagsActionTypes} from 'redux/action-types/tags';
import {RequestError, ReduxError} from 'types';

export const postTagErrorAction = (
    error: RequestError
): ReduxError<TagsActionTypes.POST_TAG_ERROR> => ({
    type: TagsActionTypes.POST_TAG_ERROR,
    payload: error,
});
