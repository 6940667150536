import {Action} from 'redux';

// custom
import {BanksActionTypes} from 'redux/action-types/banks';
import {IBank} from 'types/bank-types';

export interface IPostBankByCountrySuccessAction
  extends Action<BanksActionTypes.POST_BANK_BY_COUNTRY_SUCCESS> {
  payload: IBank;
}

export const postBankByCountrySuccessAction = (
  data: IBank
): IPostBankByCountrySuccessAction => ({
  type: BanksActionTypes.POST_BANK_BY_COUNTRY_SUCCESS,
  payload: data,
});
