import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {IPagination} from 'types';
import {IAnalytic} from 'types/transactions-type';

export interface IAnalyticsData {
    data: IAnalytic[];
    pagination: IPagination;
}

export interface IGetAnalyticsSuccessAction
    extends Action<TransactionsActionTypes.GET_ANALYTICS_SUCCESS> {
    payload: IAnalyticsData;
}

export const getAnalyticsSuccessAction = (
    data: IAnalyticsData
): IGetAnalyticsSuccessAction => ({
    type: TransactionsActionTypes.GET_ANALYTICS_SUCCESS,
    payload: data,
});
