import {Action} from 'redux';

// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';
import {IPagination} from 'types';
import {IDeposit} from 'types/deposits-types';

export interface IDepositsByCountryData {
  data: IDeposit[];
  pagination: IPagination;
}

export interface IGetDepositsByCountrySuccessAction
  extends Action<DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_SUCCESS> {
  payload: IDepositsByCountryData;
}

export const getDepositsByCountrySuccessAction = (
  data: IDepositsByCountryData
): IGetDepositsByCountrySuccessAction => ({
  type: DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_SUCCESS,
  payload: data,
});
