import {ICurrency, IPagination} from 'types';

export enum CountriesISONamesEnum {
    'SV' = 'SV',
    'GT' = 'GT',
    'PA' = 'PA',
}

// interfaces
export interface ICountryConfiguration {
    fee: string;
    currency: ICurrency;
}

export interface ICountry {
    id: number;
    name: string;
    iso2: string | CountriesISONamesEnum;
    iso3: string;
    numCode: number;
    flagUrl: string;
    createdAt: string;
    configuration: ICountryConfiguration;
    currencies: ICurrency[];
}

export interface ICountriesState {
    loading: boolean;
    loadingUpdate: boolean;
    error: unknown;
    data: ICountry[];
    pagination: IPagination;
    currentCountry: number | null;
}
