import {Action} from 'redux';

// custom
import {CountriesActionTypes} from 'redux/action-types/countries';
import {ICountry} from 'types/country-types';

export interface IPatchCountrySuccessAction
  extends Action<CountriesActionTypes.PATCH_COUNTRY_SUCCESS> {
  payload: ICountry[];
}

export const patchCountrySuccessAction = (
  data: ICountry[]
): IPatchCountrySuccessAction => ({
  type: CountriesActionTypes.PATCH_COUNTRY_SUCCESS,
  payload: data,
});
