import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {ITransactionWithIntentDataObject} from 'types/transactions-type';

export interface IGetIntentSuccessAction
    extends Action<TransactionsActionTypes.GET_INTENT_SUCCESS> {
    payload: ITransactionWithIntentDataObject;
}

export const getIntentSuccessAction = (
    data: ITransactionWithIntentDataObject
): IGetIntentSuccessAction => ({
    type: TransactionsActionTypes.GET_INTENT_SUCCESS,
    payload: data,
});
