import {Action} from 'redux';

// custom
import {BanksActionTypes} from 'redux/action-types/banks';
import {IBank} from 'types/bank-types';

export interface IDeleteBankByCountrySuccessAction
  extends Action<BanksActionTypes.DELETE_BANK_BY_COUNTRY_SUCCESS> {
  payload: IBank[];
}

export const deleteBankByCountrySuccessAction = (
  data: IBank[]
): IDeleteBankByCountrySuccessAction => ({
  type: BanksActionTypes.DELETE_BANK_BY_COUNTRY_SUCCESS,
  payload: data,
});
