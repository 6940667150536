import {DeleteCompanyKYCParams} from 'redux/actions/companies/deleteCompanyKYCRequestAction';
import {IGetCompaniesReportParams} from 'redux/actions/companies/getCompaniesReportRequestAction';
import {IGetCompaniesParams} from 'redux/actions/companies/getCompaniesRequestAction';
import {IGetCompaniesTransactionsReportParams} from 'redux/actions/companies/getCompaniesTransactionsReportRequestAction';
import {IGetCompanyDepositsParams} from 'redux/actions/companies/getCompanyDepositsRequestAction';
import {IGetCompanyNotesParams} from 'redux/actions/companies/getCompanyNotesRequestAction';
import {IGetCompanyTagsParams} from 'redux/actions/companies/getCompanyTagsRequestAction';
import {IGetCompanyTransactionsParams} from 'redux/actions/companies/getCompanyTransactionsRequestAction';
import {PatchCompanyOwnerParams} from 'redux/actions/companies/patchCompanyOwnerRequestAction';
import {PatchCompanyPOSStatusParams} from 'redux/actions/companies/patchCompanyPOSStatusRequestAction';
import {PatchCompanyParams} from 'redux/actions/companies/patchCompanyRequestAction';
import {PatchCompanySettingsParams} from 'redux/actions/companies/patchCompanySettingsRequestAction';
import {PatchCompanyStatusParams} from 'redux/actions/companies/patchCompanyStatusRequestAction';
import {IPostCompanyNoteParams} from 'redux/actions/companies/postCompanyNoteRequestAction';
import {IPostCompanyTagsParams} from 'redux/actions/companies/postCompanyTagsRequestAction';
import {
    ADMIN_API_URL,
    Methods,
    request,
    RequestOptions,
    withToken,
} from 'utils/request';

export const getCompaniesRequest = async ({
    countryId,
    ...params
}: IGetCompaniesParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/countries/${countryId}/companies`, options);
};

export const getCompanyRequest = async (companyId: number) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/cms/companies/${companyId}`, options);
};

export const getCompanyAMLRequest = async (companyId: number) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/cms/companies/${companyId}/aml-result`, options);
};

export const getCompanyKYCRequest = async (data: {
    companyId: number;
    formId: number;
}) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    const {companyId, formId} = data;

    return request(`/cms/companies/${companyId}/forms/${formId}`, options);
};

export const getCompanyTransactionsRequest = async ({
    companyId,
    ...params
}: IGetCompanyTransactionsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/companies/${companyId}/transactions`, options);
};

export const getCompanyDepositsRequest = async ({
    companyId,
    ...params
}: IGetCompanyDepositsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/companies/${companyId}/deposits`, options);
};

export const patchCompanyStatusRequest = async ({
    companyId,
}: PatchCompanyStatusParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
    });

    return request(`/cms/companies/${companyId}/toggle`, options);
};

export const getCompaniesReportRequest = async ({
    countryId,
}: IGetCompaniesReportParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/cms/countries/${countryId}/companies/report`, options);
};

export const getCompaniesTransactionsReportRequest = async ({
    countryId,
    ...params
}: IGetCompaniesTransactionsReportParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/countries/${countryId}/transactions/report?`, options);
};

export const patchCompanyRequest = async ({
    companyId,
    ...data
}: PatchCompanyParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(`/cms/companies/${companyId}`, options);
};
export const patchCompanyOwnerRequest = async ({
    companyId,
    ...data
}: PatchCompanyOwnerParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(`/cms/companies/${companyId}/users`, options);
};

export const getCompanyBillingInformationRequest = async (
    companyId: number
) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/cms/companies/${companyId}/billings`, options);
};

export const getCompanyNotesRequest = async ({
    companyId,
    ...params
}: IGetCompanyNotesParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/companies/${companyId}/notes`, options);
};

export const postCompanyNoteRequest = async ({
    id,
    ...data
}: IPostCompanyNoteParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.POST,
        data,
    });

    return request(`/cms/companies/${id}/notes`, options);
};

export const getCompanyTagsRequest = async ({
    companyId,
    ...params
}: IGetCompanyTagsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/companies/${companyId}/tags`, options);
};

export const postCompanyTagsRequest = async ({
    companyId,
    ...data
}: IPostCompanyTagsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.POST,
        data,
    });

    return request(`/cms/companies/${companyId}/tags`, options);
};

export const deleteCompanyKYCRequest = async ({
    companyId,
    ...data
}: DeleteCompanyKYCParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.DELETE,
        data,
    });

    return request(`/cms/companies/${companyId}/forms`, options);
};

export const patchCompanySettingsRequest = async ({
    companyId,
    ...data
}: PatchCompanySettingsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(`/cms/companies/${companyId}/settings`, options);
};

export const getRelatedCompaniesRequest = async (companyId: number) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/cms/companies/${companyId}/related`, options);
};

export const patchCompanyPOSStatusRequest = async ({
    companyId,
    posId,
}: PatchCompanyPOSStatusParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
    });

    return request(`/cms/companies/${companyId}/pos/${posId}/toggle`, options);
};

export const getCompanyDocumentsRequest = async (companyId: number) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        customBaseURL: ADMIN_API_URL,
    });

    return request(`/companies/${companyId}/documents`, options);
};
