import React, {useEffect, useState} from 'react';

import {Alert, Button, Card, Col, Form, Input, Row} from 'antd';
import {motion} from 'framer-motion';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, RouteComponentProps} from 'react-router-dom';
import {getCurrentUserRequestAction} from 'redux/actions/auth/getCurrentUserRequestAction';
// actions
import {postLoginRequestAction} from 'redux/actions/auth/postLoginRequestAction';
import {getCountriesRequestAction} from 'redux/actions/countries/getCountriesRequestAction';
// selectors
import {getIsloading, getIsLogged} from 'redux/selectors/auth';
// types
import {CommonProps, IStoreState, RequestError} from 'types';

import {LockOutlined, MailOutlined} from '@ant-design/icons';

// custom
import {FormStyled} from './styled';

export type SignInProps = RouteComponentProps &
    CommonProps & {
        loading: boolean;
        isLogged: boolean;
    };

export interface ISignInFields {
    email: string;
    password: string;
}

const FormItem = Form.Item;

const SignIn = ({dispatch, loading, isLogged, history}: SignInProps) => {
    useEffect(() => {
        if (isLogged) {
            return history.replace({pathname: '/'});
        }
    }, [isLogged, history]);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setErrorMessage('');
                setShowMessage(false);
            }, 3000);
        }
    });

    const intl = useIntl();
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const initialValues: ISignInFields = {
        email: process.env.REACT_APP_USER_DEV || '',
        password: process.env.REACT_APP_USER_PASSWORD_DEV || '',
    };

    const onSubmit = async (values: ISignInFields) => {
        return await new Promise((resolve, reject) => {
            dispatch(
                postLoginRequestAction(values, (error: RequestError, data) => {
                    if (!error) {
                        resolve(data);
                        dispatch(
                            getCountriesRequestAction(
                                {
                                    page: 1,
                                    perPage: 100,
                                    //TODO: change this later
                                    debugMode: true,
                                    // debugMode:
                                    //     process.env.NODE_ENV === 'development'
                                    //         ? true
                                    //         : false,
                                },
                                () => {}
                            )
                        );
                        dispatch(
                            getCurrentUserRequestAction(() => {
                                return history.push('/');
                            })
                        );
                    }

                    if (error?.data?.message) {
                        setErrorMessage(error.data.message);
                        setShowMessage(true);
                    }

                    return reject(error);
                })
            );
        });
    };

    return (
        <div className="auth-container">
            <FormStyled className="h-100">
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col xs={20} sm={20} md={20} lg={7}>
                            <Card>
                                <div className="my-4">
                                    <div className="text-center">
                                        <picture>
                                            <source
                                                srcSet="/img/cubo-logo.webp"
                                                type="image/webp"
                                            />
                                            <source
                                                srcSet="/img/cubo-logo.png"
                                                type="image/png"
                                            />
                                            <img
                                                src="/img/cubo-logo.png"
                                                alt="Cubo Logo"
                                                className="img-fluid"
                                            />
                                        </picture>
                                    </div>
                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <motion.div
                                                initial={{
                                                    opacity: 0,
                                                    marginBottom: 0,
                                                    marginTop: 0,
                                                }}
                                                animate={{
                                                    opacity: showMessage
                                                        ? 1
                                                        : 0,
                                                    marginTop: showMessage
                                                        ? 20
                                                        : 0,
                                                    marginBottom: showMessage
                                                        ? 20
                                                        : 0,
                                                }}
                                            >
                                                <Alert
                                                    type="error"
                                                    showIcon
                                                    message={errorMessage}
                                                />
                                            </motion.div>

                                            <Form
                                                layout="vertical"
                                                initialValues={initialValues}
                                                onFinish={onSubmit}
                                            >
                                                <FormItem
                                                    name="email"
                                                    label={intl.formatMessage({
                                                        id: 'common.labels.email',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                intl.formatMessage(
                                                                    {
                                                                        id: 'common.validation.email.required',
                                                                    }
                                                                ),
                                                        },
                                                        {
                                                            type: 'email',
                                                            message:
                                                                intl.formatMessage(
                                                                    {
                                                                        id: 'common.validation.email.format',
                                                                    }
                                                                ),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={
                                                            <MailOutlined className="text-primary" />
                                                        }
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    name="password"
                                                    label={
                                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                                            <span>
                                                                <FormattedMessage id="common.labels.password" />
                                                            </span>

                                                            <NavLink
                                                                to="/forgot-password"
                                                                className="cursor-pointer font-size-sm font-weight-normal text-muted"
                                                            >
                                                                <FormattedMessage id="auth.login.forgotPassword.link" />
                                                            </NavLink>
                                                        </div>
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                intl.formatMessage(
                                                                    {
                                                                        id: 'common.validation.password.required',
                                                                    }
                                                                ),
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        prefix={
                                                            <LockOutlined className="text-primary" />
                                                        }
                                                    />
                                                </FormItem>

                                                <FormItem>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        block
                                                        loading={loading}
                                                    >
                                                        <FormattedMessage id="auth.login.button.title" />
                                                    </Button>
                                                </FormItem>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </FormStyled>
        </div>
    );
};

const mapStateToProps = (state: IStoreState) => ({
    loading: getIsloading(state),
    isLogged: getIsLogged(state),
});

export default connect(mapStateToProps)(SignIn);
