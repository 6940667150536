import {AxiosResponse} from 'axios';
import get from 'lodash/get';
import {call, put, select, takeLatest} from 'redux-saga/effects';
// action types
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {deleteCompanyKYCErrorAction} from 'redux/actions/companies/deleteCompanyKYCErrorAction';
// deleteCompanyKYC
import {IDeleteCompanyKYCRequestAction} from 'redux/actions/companies/deleteCompanyKYCRequestAction';
import {deleteCompanyKYCSuccessAction} from 'redux/actions/companies/deleteCompanyKYCSuccessAction';
import {getCompaniesErrorAction} from 'redux/actions/companies/getCompaniesErrorAction';
import {getCompaniesReportErrorAction} from 'redux/actions/companies/getCompaniesReportErrorAction';
// getCompaniesReportDeposits
import {IGetCompaniesReportRequestAction} from 'redux/actions/companies/getCompaniesReportRequestAction';
import {getCompaniesReportSuccessAction} from 'redux/actions/companies/getCompaniesReportSuccesstAction';
// actions
// getCompanies
import {IGetCompaniesRequestAction} from 'redux/actions/companies/getCompaniesRequestAction';
import {
    getCompaniesSuccessAction,
    ICompaniesData,
} from 'redux/actions/companies/getCompaniesSuccesstAction';
import {getCompaniesTransactionsReportErrorAction} from 'redux/actions/companies/getCompaniesTransactionsReportErrorAction';
// getCompaniesTransactionsReportDeposits
import {IGetCompaniesTransactionsReportRequestAction} from 'redux/actions/companies/getCompaniesTransactionsReportRequestAction';
import {getCompaniesTransactionsReportSuccessAction} from 'redux/actions/companies/getCompaniesTransactionsReportSuccesstAction';
import {getCompanyAMLErrorAction} from 'redux/actions/companies/getCompanyAMLErrorAction';
// getCompanyAML
import {IGetCompanyAMLRequestAction} from 'redux/actions/companies/getCompanyAMLRequestAction';
import {getCompanyAMLSuccessAction} from 'redux/actions/companies/getCompanyAMLSuccesstAction';
import {getCompanyBillingInformationErrorAction} from 'redux/actions/companies/getCompanyBillingInformationErrorAction';
// getCompanyBillingInformation
import {IGetCompanyBillingInformationRequestAction} from 'redux/actions/companies/getCompanyBillingInformationRequestAction';
import {getCompanyBillingInformationSuccessAction} from 'redux/actions/companies/getCompanyBillingInformationSuccesstAction';
import {getCompanyDepositsErrorAction} from 'redux/actions/companies/getCompanyDepositsErrorAction';
// getCompanyDeposits
import {IGetCompanyDepositsRequestAction} from 'redux/actions/companies/getCompanyDepositsRequestAction';
import {
    getCompanyDepositsSuccessAction,
    ICompanyDepositsData,
} from 'redux/actions/companies/getCompanyDepositsSuccesstAction';
import {getCompanyDocumentsErrorAction} from 'redux/actions/companies/getCompanyDocumentsErrorAction';
import {IGetCompanyDocumentsRequestAction} from 'redux/actions/companies/getCompanyDocumentsRequestAction';
import {getCompanyDocumentsSuccessAction} from 'redux/actions/companies/getCompanyDocumentsSuccessAction';
import {getCompanyErrorAction} from 'redux/actions/companies/getCompanyErrorAction';
import {getCompanyKYCErrorAction} from 'redux/actions/companies/getCompanyKYCErrorAction';
// getCompanyKYC
import {IGetCompanyKYCRequestAction} from 'redux/actions/companies/getCompanyKYCRequestAction';
import {getCompanyKYCSuccessAction} from 'redux/actions/companies/getCompanyKYCSuccesstAction';
import {getCompanyNotesErrorAction} from 'redux/actions/companies/getCompanyNotesErrorAction';
// getCompanyNotes
import {IGetCompanyNotesRequestAction} from 'redux/actions/companies/getCompanyNotesRequestAction';
import {
    getCompanyNotesSuccessAction,
    ICompanyNotesData,
} from 'redux/actions/companies/getCompanyNotesSuccesstAction';
// getCompany
import {IGetCompanyRequestAction} from 'redux/actions/companies/getCompanyRequestAction';
import {getCompanySuccessAction} from 'redux/actions/companies/getCompanySuccesstAction';
import {getCompanyTagsErrorAction} from 'redux/actions/companies/getCompanyTagsErrorAction';
// getCompanyTags
import {IGetCompanyTagsRequestAction} from 'redux/actions/companies/getCompanyTagsRequestAction';
import {
    getCompanyTagsSuccessAction,
    ICompanyTagsData,
} from 'redux/actions/companies/getCompanyTagsSuccesstAction';
import {getCompanyTransactionsErrorAction} from 'redux/actions/companies/getCompanyTransactionsErrorAction';
// getCompanyTransactions
import {IGetCompanyTransactionsRequestAction} from 'redux/actions/companies/getCompanyTransactionsRequestAction';
import {
    getCompanyTransactionsSuccessAction,
    ICompanyTransactionsData,
} from 'redux/actions/companies/getCompanyTransactionsSuccesstAction';
import {getRelatedCompaniesErrorAction} from 'redux/actions/companies/getRelatedCompaniesErrorAction';
// getRelatedCompanies
import {IGetRelatedCompaniesRequestAction} from 'redux/actions/companies/getRelatedCompaniesRequestAction';
import {getRelatedCompaniesSuccessAction} from 'redux/actions/companies/getRelatedCompaniesSuccesstAction';
import {patchCompanyErrorAction} from 'redux/actions/companies/patchCompanyErrorAction';
import {patchCompanyOwnerErrorAction} from 'redux/actions/companies/patchCompanyOwnerErrorAction';
// patchCompanyOwner
import {IPatchCompanyOwnerRequestAction} from 'redux/actions/companies/patchCompanyOwnerRequestAction';
import {patchCompanyOwnerSuccessAction} from 'redux/actions/companies/patchCompanyOwnerSuccessAction';
import {patchCompanyPOSStatusErrorAction} from 'redux/actions/companies/patchCompanyPOSStatusErrorAction';
// patchCompanyPOSStatus
import {IPatchCompanyPOSStatusRequestAction} from 'redux/actions/companies/patchCompanyPOSStatusRequestAction';
import {patchCompanyPOSStatusSuccessAction} from 'redux/actions/companies/patchCompanyPOSStatusSuccessAction';
// patchCompany
import {IPatchCompanyRequestAction} from 'redux/actions/companies/patchCompanyRequestAction';
import {patchCompanySettingsErrorAction} from 'redux/actions/companies/patchCompanySettingsErrorAction';
// patchCompanySettings
import {IPatchCompanySettingsRequestAction} from 'redux/actions/companies/patchCompanySettingsRequestAction';
import {patchCompanySettingsSuccessAction} from 'redux/actions/companies/patchCompanySettingsSuccessAction';
import {patchCompanyStatusErrorAction} from 'redux/actions/companies/patchCompanyStatusErrorAction';
// patchCompanyStatus
import {IPatchCompanyStatusRequestAction} from 'redux/actions/companies/patchCompanyStatusRequestAction';
import {patchCompanyStatusSuccessAction} from 'redux/actions/companies/patchCompanyStatusSuccessAction';
import {patchCompanySuccessAction} from 'redux/actions/companies/patchCompanySuccessAction';
import {postCompanyNoteErrorAction} from 'redux/actions/companies/postCompanyNoteErrorAction';
// postCompanyNote
import {IPostCompanyNoteRequestAction} from 'redux/actions/companies/postCompanyNoteRequestAction';
import {postCompanyNoteSuccessAction} from 'redux/actions/companies/postCompanyNoteSuccessAction';
import {postCompanyTagsErrorAction} from 'redux/actions/companies/postCompanyTagsErrorAction';
// postCompanyTags
import {IPostCompanyTagsRequestAction} from 'redux/actions/companies/postCompanyTagsRequestAction';
import {postCompanyTagsSuccessAction} from 'redux/actions/companies/postCompanyTagsSuccessAction';
// requests
import {
    deleteCompanyKYCRequest,
    getCompaniesReportRequest,
    getCompaniesRequest,
    getCompaniesTransactionsReportRequest,
    getCompanyAMLRequest,
    getCompanyBillingInformationRequest,
    getCompanyDepositsRequest,
    getCompanyDocumentsRequest,
    getCompanyKYCRequest,
    getCompanyNotesRequest,
    getCompanyRequest,
    getCompanyTagsRequest,
    getCompanyTransactionsRequest,
    getRelatedCompaniesRequest,
    patchCompanyOwnerRequest,
    patchCompanyPOSStatusRequest,
    patchCompanyRequest,
    patchCompanySettingsRequest,
    patchCompanyStatusRequest,
    postCompanyNoteRequest,
    postCompanyTagsRequest,
} from 'services/companies';
// types
import {IPagination, RequestError} from 'types';
// types
import {IStoreState} from 'types';
import {
    ICompany,
    ICompanyDocuments,
    ICompanyNotes,
    ICompanyRelatedCompanies,
    ICompanyTag,
    IPOSInfo,
} from 'types/company-types';
// custom
import {parseErrors} from 'utils';

function* getCompanies(action: IGetCompaniesRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompaniesRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: ICompaniesData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getCompaniesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompaniesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompany(action: IGetCompanyRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyRequest,
            action.payload
        );

        yield put(getCompanySuccessAction(get(result.data, 'data', {})));

        action.callback && action.callback(null, result.data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyAML(action: IGetCompanyAMLRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyAMLRequest,
            action.payload
        );

        let data = get(result.data, 'data.result', null);

        if (!data[0]) {
            data = null;
        } else {
            data = data[0];
        }

        yield put(getCompanyAMLSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyAMLErrorAction(customError));

        action.callback && action.callback(customError);
    }
}
function* getCompanyKYC(action: IGetCompanyKYCRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyKYCRequest,
            action.payload
        );

        yield put(getCompanyKYCSuccessAction(get(result.data, 'data', {})));

        action.callback && action.callback(null, result.data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyKYCErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyTransactions(action: IGetCompanyTransactionsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyTransactionsRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: ICompanyTransactionsData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getCompanyTransactionsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyTransactionsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyDeposits(action: IGetCompanyDepositsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyDepositsRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: ICompanyDepositsData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getCompanyDepositsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyDepositsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchCompanyStatus(action: IPatchCompanyStatusRequestAction) {
    const {
        companies: {data: companiesList, detail},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchCompanyStatusRequest,
            action.payload
        );

        const dataUpdated: ICompany = get(result.data, 'data', {});

        const currentCompanyIndex = companiesList.findIndex(
            (item: ICompany) => item.id === dataUpdated.id
        );

        companiesList[currentCompanyIndex] = {
            ...companiesList[currentCompanyIndex],
            active: dataUpdated.active,
        };

        if (detail) {
            detail.active = dataUpdated.active;
        }

        yield put(patchCompanyStatusSuccessAction({companiesList, detail}));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchCompanyStatusErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompaniesReport(action: IGetCompaniesReportRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompaniesReportRequest,
            action.payload
        );

        yield put(getCompaniesReportSuccessAction(result));

        action.callback && action.callback(null, result);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompaniesReportErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompaniesTransactionsReport(
    action: IGetCompaniesTransactionsReportRequestAction
) {
    try {
        const result: AxiosResponse = yield call(
            getCompaniesTransactionsReportRequest,
            action.payload
        );

        yield put(getCompaniesTransactionsReportSuccessAction(result));

        action.callback && action.callback(null, result);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompaniesTransactionsReportErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchCompany(action: IPatchCompanyRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            patchCompanyRequest,
            action.payload
        );

        const {
            companies: {detail},
        }: IStoreState = yield select();

        const data = get(result.data, 'data', {});

        const detailUpdated = {
            ...detail,
            ...data,
        };

        yield put(patchCompanySuccessAction(detailUpdated));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchCompanyErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchCompanyOwner(action: IPatchCompanyOwnerRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            patchCompanyOwnerRequest,
            action.payload
        );

        const {
            companies: {detail},
        }: IStoreState = yield select();

        const data = get(result.data, 'data', {});

        const detailUpdated = {
            ...detail,
            owner: {
                ...detail?.owner,
                ...data,
            },
        };

        yield put(patchCompanyOwnerSuccessAction(detailUpdated));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchCompanyOwnerErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyBillingInformation(
    action: IGetCompanyBillingInformationRequestAction
) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyBillingInformationRequest,
            action.payload
        );

        const data = get(result.data, 'data', null);

        yield put(getCompanyBillingInformationSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyBillingInformationErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyNotes(action: IGetCompanyNotesRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyNotesRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: ICompanyNotesData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getCompanyNotesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyNotesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postCompanyNote(action: IPostCompanyNoteRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postCompanyNoteRequest,
            action.payload
        );
        const data: ICompanyNotes = get(result.data, 'data', {});

        yield put(postCompanyNoteSuccessAction());

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postCompanyNoteErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyTags(action: IGetCompanyTagsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyTagsRequest,
            action.payload
        );

        const data: ICompanyTagsData = {
            data: get(result.data, 'data', []),
        };

        yield put(getCompanyTagsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyTagsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postCompanyTags(action: IPostCompanyTagsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postCompanyTagsRequest,
            action.payload
        );
        const data: ICompanyTag = get(result.data, 'data', {});

        yield put(postCompanyTagsSuccessAction());

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postCompanyTagsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* deleteCompanyKYC(action: IDeleteCompanyKYCRequestAction) {
    try {
        yield call(deleteCompanyKYCRequest, action.payload);

        yield put(deleteCompanyKYCSuccessAction());

        action.callback && action.callback(null, null);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(deleteCompanyKYCErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchCompanySettings(action: IPatchCompanySettingsRequestAction) {
    const {
        companies: {data: companiesList, detail},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchCompanySettingsRequest,
            action.payload
        );

        const dataUpdated: ICompany = get(result.data, 'data', {});

        const currentCompanyIndex = companiesList.findIndex(
            (item: ICompany) => item.id === dataUpdated.id
        );

        companiesList[currentCompanyIndex] = {
            ...companiesList[currentCompanyIndex],
            active: dataUpdated.active,
        };

        if (detail) {
            detail.active = dataUpdated.active;
        }

        yield put(patchCompanySettingsSuccessAction({companiesList, detail}));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchCompanySettingsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getRelatedCompanies(action: IGetRelatedCompaniesRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getRelatedCompaniesRequest,
            action.payload
        );

        let data: ICompanyRelatedCompanies[] = get(result.data, 'data', null);

        yield put(getRelatedCompaniesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getRelatedCompaniesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchCompanyPOSStatus(action: IPatchCompanyPOSStatusRequestAction) {
    const {
        companies: {detail},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchCompanyPOSStatusRequest,
            action.payload
        );

        const {pos} = detail || {};

        const getPosIndex = (pos || []).findIndex(
            (p) => p.id === action.payload.posId
        );

        const dataUpdated: IPOSInfo = get(result.data, 'data', {});

        if (getPosIndex !== -1) {
            pos![getPosIndex].active = dataUpdated.active;
        }

        const companyDetail = {
            ...detail,
            pos: [...(pos || [])],
        };

        yield put(patchCompanyPOSStatusSuccessAction({companyDetail}));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchCompanyPOSStatusErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* getCompanyDocuments(action: IGetCompanyDocumentsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCompanyDocumentsRequest,
            action.payload
        );

        let data: ICompanyDocuments = get(result, 'data', null);

        yield put(getCompanyDocumentsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCompanyDocumentsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* companiesSagas() {
    yield takeLatest(CompaniesActionTypes.GET_COMPANIES_REQUEST, getCompanies);
    yield takeLatest(CompaniesActionTypes.GET_COMPANY_REQUEST, getCompany);
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_KYC_REQUEST,
        getCompanyKYC
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_AML_REQUEST,
        getCompanyAML
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_TRANSACTIONS_REQUEST,
        getCompanyTransactions
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_DEPOSITS_REQUEST,
        getCompanyDeposits
    );
    yield takeLatest(
        CompaniesActionTypes.PATCH_COMPANY_STATUS_REQUEST,
        patchCompanyStatus
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANIES_REPORT_REQUEST,
        getCompaniesReport
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_REQUEST,
        getCompaniesTransactionsReport
    );
    yield takeLatest(CompaniesActionTypes.PATCH_COMPANY_REQUEST, patchCompany);
    yield takeLatest(
        CompaniesActionTypes.PATCH_COMPANY_OWNER_REQUEST,
        patchCompanyOwner
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_BILLING_INFORMATION_REQUEST,
        getCompanyBillingInformation
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_NOTES_REQUEST,
        getCompanyNotes
    );
    yield takeLatest(
        CompaniesActionTypes.POST_COMPANY_NOTE_REQUEST,
        postCompanyNote
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_TAGS_REQUEST,
        getCompanyTags
    );
    yield takeLatest(
        CompaniesActionTypes.POST_COMPANY_TAGS_REQUEST,
        postCompanyTags
    );
    yield takeLatest(
        CompaniesActionTypes.DELETE_COMPANY_KYC_REQUEST,
        deleteCompanyKYC
    );
    yield takeLatest(
        CompaniesActionTypes.PATCH_COMPANY_SETTINGS_REQUEST,
        patchCompanySettings
    );
    yield takeLatest(
        CompaniesActionTypes.GET_RELATED_COMPANIES_REQUEST,
        getRelatedCompanies
    );
    yield takeLatest(
        CompaniesActionTypes.PATCH_COMPANY_POS_STATUS_REQUEST,
        patchCompanyPOSStatus
    );
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_DOCUMENTS_REQUEST,
        getCompanyDocuments
    );
}
