import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {connect} from 'react-redux';
import * as storage from 'localforage';

// custom
import {CommonProps, IStoreState} from 'types';

// actions
import {logoutAction} from 'redux/actions/auth/logoutAction';

export type SignOutProps = RouteComponentProps & CommonProps;

const SignOut: React.FC<SignOutProps> = ({dispatch, history}) => {
    useEffect(() => {
        storage.clear(() => {
            dispatch(logoutAction());
        });

        return history.push('/signin');
    });

    return null;
};

const mapStateToProps = (state: IStoreState) => ({});

export default connect(mapStateToProps)(SignOut);
