import styled from 'styled-components';

export const Logo = styled.div<{navCollapsed: boolean}>`
  width: ${({navCollapsed}) => (navCollapsed ? '80px' : '250px')};
`;

export const Nav = styled.div<{navCollapsed: boolean}>`
  width: calc(100% - ${({navCollapsed}) => (navCollapsed ? '80px' : '250px')});
`;

export const NavLeft = styled.div``;
export const NavRight = styled.div``;

export const HeaderWrapper = styled.div``;
