import {IGetTagsParams} from 'redux/actions/tags/getTagsRequestAction';
import {PatchTagParams} from 'redux/actions/tags/patchTagRequestAction';
import {ITagFormFields} from 'pages/dashboard/settings/tags/TagForm';
import {request, Methods, withToken, RequestOptions} from 'utils/request';

export const getTagsRequest = async (params: IGetTagsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/tags`, options);
};

export const postTagsRequest = async (data: ITagFormFields) => {
    const options: RequestOptions = await withToken({
        method: Methods.POST,
        data,
    });

    return request('/cms/tags', options);
};

export const patchTagRequest = async ({tagId, ...data}: PatchTagParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(`/cms/tags/${tagId}`, options);
};

export const deleteTagRequest = async (tagId: number) => {
    const options: RequestOptions = await withToken({
        method: Methods.DELETE,
    });

    return request(`/cms/tags/${tagId}`, options);
};
