import {DeleteTransactionsParams} from 'redux/actions/transactions/deleteTransactionsRequestAction';
import {IGetAnalyticsParams} from 'redux/actions/transactions/getAnalyticsRequestAction';
import {IGetIntentNotesParams} from 'redux/actions/transactions/getIntentNotesRequestAction';
import {IGetIntentParams} from 'redux/actions/transactions/getIntentRequestAction';
import {IGetTransactionIntentsParams} from 'redux/actions/transactions/getTransactionIntentsRequestAction';
import {IGetTransactionsParams} from 'redux/actions/transactions/getTransactionsRequestAction';
import {PatchTransactionDepositStatusParams} from 'redux/actions/transactions/patchTransactionDepositStatusRequestAction';
import {IPostIntentNoteParams} from 'redux/actions/transactions/postIntentNoteRequestAction';
import {Methods, request, RequestOptions, withToken} from 'utils/request';

export const getAnalyticsRequest = async (params: IGetAnalyticsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request('/cms/transactions/analytics', options);
};

export const getTransactionsRequest = async ({
    countryId,
    ...params
}: IGetTransactionsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`cms/countries/${countryId}/transactions`, options);
};

export const getTransactionIntentsRequest = async ({
    countryId,
    transactionId,
}: IGetTransactionIntentsParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(
        `/cms/countries/${countryId}/transactions/${transactionId}/intents`,
        options
    );
};

export const getIntentRequest = async ({
    countryId,
    intentId,
}: IGetIntentParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(
        `/cms/countries/${countryId}/transactions/intent/${intentId}`,
        options
    );
};

export const patchTransactionDepositStatusRequest = async ({
    transactionId,
    intentId,
    ...data
}: PatchTransactionDepositStatusParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(
        `/cms/transactions/${transactionId}/transaction-intents/${intentId}`,
        options
    );
};

export const getIntentNotesRequest = async ({
    transactionId,
    intentId,
    ...params
}: IGetIntentNotesParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(
        `/cms/transactions/${transactionId}/transaction-intents/${intentId}/notes`,
        options
    );
};

export const postIntentNoteRequest = async ({
    transactionId,
    intentId,
    ...data
}: IPostIntentNoteParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.POST,
        data,
    });

    return request(
        `/cms/transactions/${transactionId}/transaction-intents/${intentId}/notes`,
        options
    );
};

export const deleteTransactionsRequest = async (
    data: DeleteTransactionsParams
) => {
    const options: RequestOptions = await withToken({
        method: Methods.DELETE,
        data,
    });

    return request(`/cms/transactions`, options);
};
