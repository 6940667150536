import {takeLatest, call, put, select} from 'redux-saga/effects';
import get from 'lodash/get';
import {AxiosResponse} from 'axios';

// custom
import {parseErrors} from 'utils';

// action types
import {BanksActionTypes} from 'redux/action-types/banks';

// requests
import {
    getBanksByCountryRequest,
    postBankByCountryRequest,
    patchBankByCountryRequest,
    deleteBankByCountryRequest,
} from 'services/banks';

// actions
import {
    IBanksData,
    getBanksByCountrySuccessAction,
} from 'redux/actions/banks/getBanksByCountrySuccesstAction';
import {getBanksByCountryErrorAction} from 'redux/actions/banks/getBanksByCountryErrorAction';
import {postBankByCountrySuccessAction} from 'redux/actions/banks/postBankByCountrySuccessAction';
import {postBankByCountryErrorAction} from 'redux/actions/banks/postBankByCountryErrorAction';
import {patchBankByCountrySuccessAction} from 'redux/actions/banks/patchBankByCountrySuccessAction';
import {patchBankByCountryErrorAction} from 'redux/actions/banks/patchBankByCountryErrorAction';
import {deleteBankByCountrySuccessAction} from 'redux/actions/banks/deleteBankByCountrySuccessAction';
import {deleteBankByCountryErrorAction} from 'redux/actions/banks/deleteBankByCountryErrorAction';

// types
import {IGetBanksByCountryRequestAction} from 'redux/actions/banks/getBanksByCountryRequestAction';
import {IPostBankByCountryRequestAction} from 'redux/actions/banks/postBankByCountryRequestAction';
import {IPatchBankByCountryRequestAction} from 'redux/actions/banks/patchBankByCountryRequestAction';
import {IDeleteBankByCountryRequestAction} from 'redux/actions/banks/deleteBankByCountryRequestAction';
import {IPagination, IStoreState, RequestError} from 'types';
import {IBank} from 'types/bank-types';

function* getBanksByCountry(action: IGetBanksByCountryRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getBanksByCountryRequest,
            action.payload
        );
        const pagination: IPagination = get(result.data, 'pagination', {});
        const data: IBanksData = {
            data: get(result.data, 'data', []),
            pagination: {page: pagination.page, total: pagination.total},
        };

        yield put(getBanksByCountrySuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getBanksByCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postBankByCountry(action: IPostBankByCountryRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postBankByCountryRequest,
            action.payload
        );
        const data: IBank = {
            name: get(result.data, 'data.name'),
            id: get(result.data, 'data.id'),
            bankCode: get(result.data, 'data.bankCode'),
        };

        yield put(postBankByCountrySuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postBankByCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchBankByCountry(action: IPatchBankByCountryRequestAction) {
    const {
        banks: {data: bankList},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchBankByCountryRequest,
            action.payload
        );
        const dataUpdated: IBank = get(result.data, 'data', {});
        const currentBankIndex = bankList.findIndex(
            (item: IBank) => item.id === dataUpdated.id
        );

        bankList[currentBankIndex] = {
            name: dataUpdated.name,
            id: dataUpdated.id,
            bankCode: dataUpdated.bankCode,
        };

        yield put(patchBankByCountrySuccessAction(bankList));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchBankByCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* deleteBankByCountry(action: IDeleteBankByCountryRequestAction) {
    const {
        banks: {data: bankList},
    }: IStoreState = yield select();

    try {
        yield call(deleteBankByCountryRequest, action.payload);

        const data: IBank[] = bankList.filter(
            (item: IBank) => item.id !== action.payload
        );

        yield put(deleteBankByCountrySuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(deleteBankByCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* banksSagas() {
    yield takeLatest(
        BanksActionTypes.GET_BANKS_BY_COUNTRY_REQUEST,
        getBanksByCountry
    );
    yield takeLatest(
        BanksActionTypes.POST_BANK_BY_COUNTRY_REQUEST,
        postBankByCountry
    );
    yield takeLatest(
        BanksActionTypes.PATCH_BANK_BY_COUNTRY_REQUEST,
        patchBankByCountry
    );
    yield takeLatest(
        BanksActionTypes.DELETE_BANK_BY_COUNTRY_REQUEST,
        deleteBankByCountry
    );
}
