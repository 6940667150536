import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {ICompanyAML} from 'types/company-types';

export interface IGetCompanyAMLSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_AML_SUCCESS> {
    payload: ICompanyAML;
}

export const getCompanyAMLSuccessAction = (
    data: ICompanyAML
): IGetCompanyAMLSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_AML_SUCCESS,
    payload: data,
});
