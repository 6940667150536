// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';
import {RequestError, ReduxError} from 'types';

export const patchDepositErrorAction = (
    error: RequestError
): ReduxError<DepositsActionTypes.PATCH_DEPOSIT_ERROR> => ({
    type: DepositsActionTypes.PATCH_DEPOSIT_ERROR,
    payload: error,
});
