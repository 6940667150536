import {connect} from 'react-redux';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {getIsLogged} from 'redux/selectors/auth';
import {IStoreState} from 'types';

type PrivateRouteProps = {
    isLogged: boolean;
    component: Function;
    computedMatch?: {
        isExact: boolean;
        params: any; // {}
        path: string;
        url: string;
    };
    location?: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: any;
    };
    path?: string;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    isLogged,
    component: Component,
    ...props
}) => {
    return (
        <Route
            {...props}
            component={(props: RouteProps) =>
                isLogged ? <Component {...props} /> : <Redirect to="/signin" />
            }
        />
    );
};

const mapStateToProps = (state: IStoreState) => ({
    isLogged: getIsLogged(state),
});

export default connect(mapStateToProps)(PrivateRoute);
