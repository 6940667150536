import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {IPagination} from 'types';
import {IIntentNotes} from 'types/transactions-type';

export interface IIntentNotesData {
    data: IIntentNotes[];
    pagination: IPagination;
}

export interface IGetIntentNotesSuccessAction
    extends Action<TransactionsActionTypes.GET_INTENT_NOTES_SUCCESS> {
    payload: IIntentNotesData;
}

export const getIntentNotesSuccessAction = (
    data: IIntentNotesData
): IGetIntentNotesSuccessAction => ({
    type: TransactionsActionTypes.GET_INTENT_NOTES_SUCCESS,
    payload: data,
});
