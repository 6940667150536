import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {IPagination} from 'types';
import {ITransaction} from 'types/transactions-type';

export interface ICompanyTransactionsData {
    data: ITransaction[];
    pagination: IPagination;
}

export interface IGetCompanyTransactionsSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_TRANSACTIONS_SUCCESS> {
    payload: ICompanyTransactionsData;
}

export const getCompanyTransactionsSuccessAction = (
    data: ICompanyTransactionsData
): IGetCompanyTransactionsSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_TRANSACTIONS_SUCCESS,
    payload: data,
});
