import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IDeleteCompanyKYCSuccessAction
    extends Action<CompaniesActionTypes.DELETE_COMPANY_KYC_SUCCESS> {
    payload: any;
}

export const deleteCompanyKYCSuccessAction =
    (): IDeleteCompanyKYCSuccessAction => ({
        type: CompaniesActionTypes.DELETE_COMPANY_KYC_SUCCESS,
        payload: null,
    });
