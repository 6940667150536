import {put, takeEvery} from 'redux-saga/effects';

// types
import {ErrorAction} from 'redux/actions';
import {AuthActionTypes} from 'redux/action-types/auth';

// actions
import {postRefreshTokenRequestAction} from 'redux/actions/auth/postRefreshTokenRequestAction';

export function* catchError(action: ErrorAction) {
  try {
    const status: number = action.payload ? action.payload.status ?? 0 : 0;

    if (status === 401 && action.type !== AuthActionTypes.POST_LOGIN_ERROR) {
      yield put(postRefreshTokenRequestAction());
    }

    if (status === 500) {
      window.location.href = '/500';
    }
  } catch (error) {
    window.location.href = '/signout';
  }
}

export function* sagaErrors() {
  yield takeEvery('*', catchError);
}
