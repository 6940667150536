// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {RequestError, ReduxError} from 'types';

export const postIntentNoteErrorAction = (
    error: RequestError
): ReduxError<TransactionsActionTypes.POST_INTENT_NOTE_ERROR> => ({
    type: TransactionsActionTypes.POST_INTENT_NOTE_ERROR,
    payload: error,
});
