// custom
import {CategoriesActionTypes} from 'redux/action-types/categories';
import {RequestError, ReduxError} from 'types';

export const getCategoriesErrorAction = (
    error: RequestError
): ReduxError<CategoriesActionTypes.GET_CATEGORIES_ERROR> => ({
    type: CategoriesActionTypes.GET_CATEGORIES_ERROR,
    payload: error,
});
