import {takeLatest, call, put, select} from 'redux-saga/effects';
import get from 'lodash/get';
import {AxiosResponse} from 'axios';

// custom
import {parseErrors} from 'utils';

// action types
import {TagsActionTypes} from 'redux/action-types/tags';

// requests
import {
    getTagsRequest,
    postTagsRequest,
    patchTagRequest,
    deleteTagRequest,
} from 'services/tags';

// getTags
import {IGetTagsRequestAction} from 'redux/actions/tags/getTagsRequestAction';
import {
    ITagsData,
    getTagsSuccessAction,
} from 'redux/actions/tags/getTagsSuccesstAction';
import {getTagsErrorAction} from 'redux/actions/tags/getTagsErrorAction';
// postTag
import {IPostTagRequestAction} from 'redux/actions/tags/postTagRequestAction';
import {postTagSuccessAction} from 'redux/actions/tags/postTagSuccessAction';
import {postTagErrorAction} from 'redux/actions/tags/postTagErrorAction';
// patchTag
import {IPatchTagRequestAction} from 'redux/actions/tags/patchTagRequestAction';
import {patchTagSuccessAction} from 'redux/actions/tags/patchTagSuccessAction';
import {patchTagErrorAction} from 'redux/actions/tags/patchTagErrorAction';
// deleteTag
import {IDeleteTagRequestAction} from 'redux/actions/tags/deleteTagRequestAction';
import {deleteTagSuccessAction} from 'redux/actions/tags/deleteTagSuccessAction';
import {deleteTagErrorAction} from 'redux/actions/tags/deleteTagErrorAction';

import {IStoreState, RequestError} from 'types';
import {ITag} from 'types/tag-types';

function* getTags(action: IGetTagsRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getTagsRequest,
            action.payload
        );

        const data: ITagsData = {
            data: get(result.data, 'data', []),
        };

        yield put(getTagsSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getTagsErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postTag(action: IPostTagRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postTagsRequest,
            action.payload
        );
        const data: ITag = get(result.data, 'data', {});

        yield put(postTagSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postTagErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchTag(action: IPatchTagRequestAction) {
    const {
        tags: {data: tagList},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchTagRequest,
            action.payload
        );
        const dataUpdated: ITag = get(result.data, 'data', {});
        const currentTagIndex = tagList.findIndex(
            (item: ITag) => item.id === dataUpdated.id
        );

        tagList[currentTagIndex] = {
            ...tagList[currentTagIndex],
            tagName: dataUpdated.tagName,
        };

        yield put(patchTagSuccessAction(tagList));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchTagErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* deleteTag(action: IDeleteTagRequestAction) {
    const {
        tags: {data: tagList},
    }: IStoreState = yield select();

    try {
        yield call(deleteTagRequest, action.payload);

        const data: ITag[] = tagList.filter(
            (item: ITag) => item.id !== action.payload
        );

        yield put(deleteTagSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(deleteTagErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* tagsSagas() {
    yield takeLatest(TagsActionTypes.GET_TAGS_REQUEST, getTags);
    yield takeLatest(TagsActionTypes.POST_TAG_REQUEST, postTag);
    yield takeLatest(TagsActionTypes.PATCH_TAG_REQUEST, patchTag);
    yield takeLatest(TagsActionTypes.DELETE_TAG_REQUEST, deleteTag);
}
