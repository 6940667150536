import {useState, useEffect, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import qs from 'query-string';

// utils
import {isValueNumber, parseQueryParamsValues} from 'utils';
import {setCurrentCountryAction} from 'redux/actions/countries/setCurrentCountryAction';

// types
import {IStoreState} from 'types';

export const usePageSize = (pageSizeNumber: number = 20) => {
    const [perPage, setPerPage] = useState<number>(pageSizeNumber);
    return {
        perPage,
        setPerPage,
    };
};

type getPageQueryParamParams = {page: number};
export const useGetPageQueryParam = ({page}: getPageQueryParamParams) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState<number>(page);

    useEffect(() => {
        const queryParams = qs.parse(location.search);
        const pageParam = Number(queryParams?.page) || 1;

        setCurrentPage(pageParam);
    }, [location]);

    return {
        currentPage,
        setCurrentPage,
    };
};

export const useGetQueryParams = <T>() => {
    const location = useLocation();
    const [params, setParams] = useState<{[key: string]: any} | null>(null);

    useEffect(() => {
        const queryParams = qs.parse(location.search);
        const parsedParams: {[key: string]: any} = {};

        Object.keys(queryParams).map((key: string) => {
            const parsedValue = parseQueryParamsValues(queryParams[key]);
            return (parsedParams[key] = parsedValue);
        });

        setParams(parsedParams);
    }, [location]);

    return params as T;
};

export const useSetCurrentCountry = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {currentCountry, data: countries} = useSelector(
        (state: IStoreState) => state.countries
    );

    const queryParams: any = qs.parse(location.search);

    const handleSetCurrentCountry = (countryId: number) => {
        if ('countryId' in queryParams) {
            location.search = qs.stringify({
                ...queryParams,
                countryId,
            });
        }
        return dispatch(setCurrentCountryAction(countryId));
    };

    const getCountryFromURL = useCallback(
        async (countryId: number) => {
            return dispatch(setCurrentCountryAction(countryId));
        },
        [dispatch]
    );

    useEffect(() => {
        if ('countryId' in queryParams) {
            const countryValue = isValueNumber(queryParams.countryId as string);
            const countrySelected = countryValue
                ? +queryParams.countryId
                : currentCountry
                ? currentCountry
                : countries[0].id;
            getCountryFromURL(countrySelected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCountryFromURL]);

    return {
        handleSetCurrentCountry,
    };
};

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

export const useEscapeKey = (handleClose: () => void) => {
    const handleEscKey = useCallback(
        (event) => {
            if (event.key === KEY_NAME_ESC) {
                handleClose();
            }
        },
        [handleClose]
    );

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
    }, [handleEscKey]);
};
