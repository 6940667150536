import {Reducer} from 'redux';

// custom
import {TagsActions} from 'redux/actions/tags';
import {TagsActionTypes} from 'redux/action-types/tags';
import {AuthActionTypes} from 'redux/action-types/auth';
import {ITagsState} from 'types/tag-types';

const tagsInitialState: ITagsState = {
    data: [],
    error: null,
    loading: false,
    loadingUpdate: false,
};

export const TagsReducer: Reducer<ITagsState, TagsActions> = (
    state = tagsInitialState,
    action
) => {
    switch (action.type) {
        case TagsActionTypes.GET_TAGS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case TagsActionTypes.GET_TAGS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                error: null,
                loading: false,
            };
        case TagsActionTypes.GET_TAGS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case TagsActionTypes.POST_TAG_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case TagsActionTypes.POST_TAG_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                error: null,
                loadingUpdate: false,
            };
        case TagsActionTypes.POST_TAG_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case TagsActionTypes.PATCH_TAG_REQUEST:
        case TagsActionTypes.DELETE_TAG_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case TagsActionTypes.PATCH_TAG_SUCCESS:
        case TagsActionTypes.DELETE_TAG_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                loadingUpdate: false,
            };
        case TagsActionTypes.PATCH_TAG_ERROR:
        case TagsActionTypes.DELETE_TAG_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case AuthActionTypes.LOGOUT:
            return tagsInitialState;
        default:
            return state;
    }
};
