import axios from 'axios';
import * as storage from 'localforage';
import {store} from 'redux/store';

export const API_URL =
    process.env.REACT_APP_API_URL || 'https://cubo-stg.herokuapp.com/api/v1';

export const ADMIN_API_URL =
    process.env.REACT_APP_ADMIN_API_URL ||
    'https://cubo-stg.herokuapp.com/api/v1';

interface Headers {
    Accept: string;
    Authorization?: string;
    'Content-Type': string;
}

export enum Methods {
    HEAD = 'HEAD',
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export type RequestOptions = {
    data?: object;
    params?: object;
    method: Methods;
    headers?: object;
    token?: string | null | unknown;
    customBaseURL?: string;
};

export const URLEncoded = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

export const Multipart = {
    'Content-Type': 'multipart/form-data',
};

export const JSONBody = {
    'Content-Type': 'application/json',
};

export const RequestType = {
    URLEncoded,
    Multipart,
    JSONBody,
};

export const withToken = async (options: RequestOptions) => {
    const token = await storage.getItem('token');

    return {...options, token};
};

export const getOptions = ({
    data,
    method,
    token = null,
    headers: extraHeaders = {},
    params = {},
    customBaseURL,
}: RequestOptions) => {
    const headers: Headers = {
        Accept: 'application/json',
        ...JSONBody,
        ...extraHeaders,
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const options = {
        baseURL: customBaseURL ?? API_URL,
        method,
        data,
        headers,
        params,
    };

    return options;
};

export const getSelectedCountry = () => {
    const state = store.getState();

    return state.countries.currentCountry;
};

export const request = (url: string, options: RequestOptions) => {
    const selectedCountryId = getSelectedCountry();

    const formatOptions: RequestOptions = {
        ...options,
        params: {
            ...(selectedCountryId && {
                countryId: selectedCountryId,
            }),
            ...options.params,
        },
    };

    return axios({url, ...getOptions(formatOptions)});
};
