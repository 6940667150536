import {Action} from 'redux';

// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';

export interface IPostDepositByCountrySuccessAction
  extends Action<DepositsActionTypes.POST_DEPOSIT_BY_COUNTRY_SUCCESS> {}

export const postDepositByCountrySuccessAction = (): IPostDepositByCountrySuccessAction => ({
  type: DepositsActionTypes.POST_DEPOSIT_BY_COUNTRY_SUCCESS,
});
