import {Action} from 'redux';

// custom
import {BanksActionTypes} from 'redux/action-types/banks';
import {IPagination} from 'types';
import {IBank} from 'types/bank-types';

export interface IBanksData {
  data: IBank[];
  pagination: IPagination;
}

export interface IGetBanksByCountrySuccessAction
  extends Action<BanksActionTypes.GET_BANKS_BY_COUNTRY_SUCCESS> {
  payload: IBanksData;
}

export const getBanksByCountrySuccessAction = (
  data: IBanksData
): IGetBanksByCountrySuccessAction => ({
  type: BanksActionTypes.GET_BANKS_BY_COUNTRY_SUCCESS,
  payload: data,
});
