import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {ICurrentUser} from 'types/auth-types';

export interface IGetCurrenUserSuccessAction
    extends Action<AuthActionTypes.GET_CURRENT_USER_SUCCESS> {
    payload: ICurrentUser;
}

export const getCurrenUserSuccessAction = (
    data: ICurrentUser
): IGetCurrenUserSuccessAction => ({
    type: AuthActionTypes.GET_CURRENT_USER_SUCCESS,
    payload: data,
});
