import {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

const Transactions = lazy(() => import('pages/dashboard/transactions'));
const IntentDetail = lazy(
    () => import('pages/dashboard/transactions/intentDetail')
);

const TransactionsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/transactions" component={Transactions} />
            <Route
                exact
                path="/transactions/:transactionId/:countryId/intent/:intentId"
                component={IntentDetail}
            />
        </Switch>
    );
};

export default TransactionsRoutes;
