import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IPostCompanyNoteSuccessAction
    extends Action<CompaniesActionTypes.POST_COMPANY_NOTE_SUCCESS> {}

export const postCompanyNoteSuccessAction =
    (): IPostCompanyNoteSuccessAction => ({
        type: CompaniesActionTypes.POST_COMPANY_NOTE_SUCCESS,
    });
