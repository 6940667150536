import {Reducer} from 'redux';
import {AuthActionTypes} from 'redux/action-types/auth';
import {CountriesActionTypes} from 'redux/action-types/countries';
import {CountriesActions} from 'redux/actions/countries';
// custom
import {ICountriesState} from 'types/country-types';

const countriesInitialState: ICountriesState = {
    data: [],
    error: null,
    loading: false,
    loadingUpdate: false,
    pagination: {
        page: 1,
        perPage: 15,
        total: 0,
    },
    currentCountry: null,
};

export const CountriesReducer: Reducer<ICountriesState, CountriesActions> = (
    state = countriesInitialState,
    action
) => {
    switch (action.type) {
        case CountriesActionTypes.GET_COUNTRIES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case CountriesActionTypes.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: {...state.pagination, ...action.payload.pagination},
                currentCountry: action.payload.currentCountry,
                error: null,
                loading: false,
            };
        case CountriesActionTypes.GET_COUNTRIES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CountriesActionTypes.PATCH_COUNTRY_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case CountriesActionTypes.PATCH_COUNTRY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                loadingUpdate: false,
            };
        case CountriesActionTypes.PATCH_COUNTRY_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case CountriesActionTypes.SET_CURRENT_COUNTRY:
            return {...state, currentCountry: action.payload};
        case AuthActionTypes.LOGOUT:
            return countriesInitialState;
        default:
            return state;
    }
};
