import {Suspense} from 'react';

import {connect} from 'react-redux';
import {Switch} from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';
import {PermissionEnum} from 'types/settings-type';

// custom
import Dashboard from '../../layouts/Dashboard';
// selectors
import {
    getCurrentUser,
    getCurrentUserCompaniesPermission,
    getCurrentUserContactCenterPermission,
    getCurrentUserDepositsPermission,
    getCurrentUserReportsPermission,
    getCurrentUserSettingsPermission,
    getCurrentUserTransactionsPermission,
} from '../../redux/selectors/auth';
// types
import {IStoreState} from '../../types';
// Companies
import CompaniesRoutes from './Companies';
// Deposits
import DepositsRoutes from './Deposits';
//* DASHBOARD
// Home
import HomeRoute from './Home';
// Reports
import ReportsRoutes from './Reports';
// Settings
import SettingsRoutes from './Settings';
// Transactions
import TransactionsRoutes from './Transactions';

type DashboardRoutesProps = {
    depositsPermissions: PermissionEnum | undefined;
    companiesPermissions: PermissionEnum | undefined;
    reportsPermissions: PermissionEnum | undefined;
    transactionsPremissions: PermissionEnum | undefined;
    settingsPermissions: PermissionEnum | undefined;
    contactCenterPermissions: PermissionEnum | undefined;
};

const DashboardRoutes: React.FC<DashboardRoutesProps> = ({
    depositsPermissions,
    companiesPermissions,
    reportsPermissions,
    transactionsPremissions,
    settingsPermissions,
    contactCenterPermissions,
}) => {
    // const userHasNotContactCenterPermissions =
    //     contactCenterPermissions === PermissionEnum.None;
    return (
        <Dashboard>
            <Suspense fallback={<div>Cargando...</div>}>
                <Switch>
                    {depositsPermissions &&
                        depositsPermissions !== PermissionEnum.None && (
                            <PrivateRoute
                                path="/deposits"
                                component={DepositsRoutes}
                            />
                        )}
                    {companiesPermissions &&
                        companiesPermissions !== PermissionEnum.None && (
                            <PrivateRoute
                                path="/companies"
                                component={CompaniesRoutes}
                            />
                        )}
                    {reportsPermissions &&
                        reportsPermissions !== PermissionEnum.None && (
                            <PrivateRoute
                                path="/reports"
                                component={ReportsRoutes}
                            />
                        )}
                    {settingsPermissions &&
                        settingsPermissions !== PermissionEnum.None && (
                            <PrivateRoute
                                path="/settings"
                                component={SettingsRoutes}
                            />
                        )}
                    {transactionsPremissions &&
                        transactionsPremissions !== PermissionEnum.None && (
                            <PrivateRoute
                                path="/transactions"
                                component={TransactionsRoutes}
                            />
                        )}
                    <PrivateRoute path="/" component={HomeRoute} />
                </Switch>
            </Suspense>
        </Dashboard>
    );
};

const mapStateToProps = (state: IStoreState) => ({
    currentUser: getCurrentUser(state),
    depositsPermissions: getCurrentUserDepositsPermission(state),
    companiesPermissions: getCurrentUserCompaniesPermission(state),
    reportsPermissions: getCurrentUserReportsPermission(state),
    transactionsPremissions: getCurrentUserTransactionsPermission(state),
    settingsPermissions: getCurrentUserSettingsPermission(state),
    contactCenterPermissions: getCurrentUserContactCenterPermission(state),
});

export default connect(mapStateToProps)(DashboardRoutes);
