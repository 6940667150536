import createSagaMiddleware from 'redux-saga';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {persistStore} from 'redux-persist';

// custom
import {reducers} from 'redux/reducers';
import {sagas} from 'redux/sagas';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);

sagas.forEach(sagaMiddleware.run);

export {store, persistor};
