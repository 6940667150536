import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';

export interface IPostIntentNoteSuccessAction
    extends Action<TransactionsActionTypes.POST_INTENT_NOTE_SUCCESS> {}

export const postIntentNoteSuccessAction =
    (): IPostIntentNoteSuccessAction => ({
        type: TransactionsActionTypes.POST_INTENT_NOTE_SUCCESS,
    });
