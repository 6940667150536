import {Reducer} from 'redux';
import {AuthActionTypes} from 'redux/action-types/auth';
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {TransactionsActions} from 'redux/actions/transactions';
// custom
import {ITransactionsState} from 'types/transactions-type';

const transactionsInitialState: ITransactionsState = {
    data: [],
    error: null,
    loading: false,
    loadingUpdate: false,
    pagination: {
        page: 1,
        perPage: 15,
        total: 0,
    },
    loadingIntents: false,
    intents: [],
    analytics: {
        data: [],
        error: null,
        loading: false,
        loadingUpdate: false,
    },
    intentDetail: {
        data: null,
        error: null,
        loading: false,
        loadingUpdate: false,
    },
    notes: {
        data: [],
        loading: false,
        loadingUpdate: false,
        loadingNewNote: false,
        pagination: {
            page: 1,
            perPage: 20,
            total: 0,
        },
    },
};

export const TransactionsReducer: Reducer<
    ITransactionsState,
    TransactionsActions
> = (state = transactionsInitialState, action) => {
    switch (action.type) {
        case TransactionsActionTypes.GET_ANALYTICS_REQUEST:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    error: null,
                    loading: true,
                },
            };
        case TransactionsActionTypes.GET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    data: action.payload.data,
                    error: null,
                    loading: false,
                },
            };
        case TransactionsActionTypes.GET_ANALYTICS_ERROR:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    error: action.payload,
                    loading: false,
                },
            };
        case TransactionsActionTypes.GET_TRANSACTIONS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: {...state.pagination, ...action.payload.pagination},
                error: null,
                loading: false,
            };
        case TransactionsActionTypes.GET_TRANSACTIONS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case TransactionsActionTypes.GET_TRANSACTION_INTENTS_REQUEST:
            return {
                ...state,
                intents: [],
                error: null,
                loadingIntents: true,
            };
        case TransactionsActionTypes.GET_TRANSACTION_INTENTS_SUCCESS:
            return {
                ...state,
                intents: action.payload,
                error: null,
                loadingIntents: false,
            };
        case TransactionsActionTypes.GET_TRANSACTION_INTENTS_ERROR:
            return {
                ...state,
                intents: [],
                error: action.payload,
                loadingIntents: false,
            };
        case TransactionsActionTypes.GET_INTENT_REQUEST:
            return {
                ...state,
                intentDetail: {
                    ...state.intentDetail,
                    error: null,
                    loading: true,
                },
            };
        case TransactionsActionTypes.GET_INTENT_SUCCESS:
            return {
                ...state,
                intentDetail: {
                    ...state.intentDetail,
                    error: null,
                    data: action.payload,
                    loading: false,
                },
            };
        case TransactionsActionTypes.GET_INTENT_ERROR:
            return {
                ...state,
                intentDetail: {
                    ...state.intentDetail,
                    error: action.payload,
                    loading: false,
                },
            };
        case TransactionsActionTypes.GET_INTENT_NOTES_REQUEST:
            return {
                ...state,
                error: null,
                notes: {
                    ...state.notes,
                    loading: true,
                },
            };
        case TransactionsActionTypes.GET_INTENT_NOTES_SUCCESS:
            return {
                ...state,
                error: null,
                notes: {
                    ...state.notes,
                    loading: false,
                    data: action.payload.data,
                    pagination: {
                        ...state.notes.pagination,
                        ...action.payload.pagination,
                    },
                },
            };
        case TransactionsActionTypes.GET_INTENT_NOTES_ERROR:
            return {
                ...state,
                error: action,
                notes: {
                    ...state.notes,
                    loading: false,
                },
            };
        case TransactionsActionTypes.POST_INTENT_NOTE_REQUEST:
            return {
                ...state,
                error: null,
                notes: {
                    ...state.notes,
                    loadingNewNote: true,
                },
            };
        case TransactionsActionTypes.POST_INTENT_NOTE_SUCCESS:
            return {
                ...state,
                error: null,
                notes: {
                    ...state.notes,
                    loadingNewNote: false,
                },
            };
        case TransactionsActionTypes.POST_INTENT_NOTE_ERROR:
            return {
                ...state,
                error: action.payload,
                notes: {
                    ...state.notes,
                    loadingNewNote: false,
                },
            };
        case TransactionsActionTypes.PATCH_TRANSACTION_DEPOSIT_STATUS_REQUEST:
            return {
                ...state,
                intentDetail: {
                    ...state.intentDetail,
                    error: null,
                    loadingUpdate: true,
                },
            };
        case TransactionsActionTypes.PATCH_TRANSACTION_DEPOSIT_STATUS_SUCCESS:
            return {
                ...state,
                intentDetail: {
                    ...state.intentDetail,
                    error: null,
                    data: action.payload,
                    loadingUpdate: false,
                },
            };
        case TransactionsActionTypes.PATCH_TRANSACTION_DEPOSIT_STATUS_ERROR:
            return {
                ...state,
                intentDetail: {
                    ...state.intentDetail,
                    error: action.payload,
                    loadingUpdate: false,
                },
            };
        case TransactionsActionTypes.DELETE_TRANSACTIONS_REQUEST:
            return {
                ...state,
                error: null,
            };
        case TransactionsActionTypes.DELETE_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                error: null,
            };
        case TransactionsActionTypes.DELETE_TRANSACTIONS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case AuthActionTypes.LOGOUT:
            return transactionsInitialState;
        default:
            return state;
    }
};
