import {createSelector} from 'reselect';
// custom
import {IStoreState} from 'types';
import {IAuthState} from 'types/auth-types';
import {ICountriesState} from 'types/country-types';

import {countries} from './countries';

const auth = (state: IStoreState) => state.auth;

export const getIsLogged = createSelector(
    auth,
    (state: IAuthState) => state.isLogged
);

export const getIsloading = createSelector(
    auth,
    (state: IAuthState) => state.loading
);

export const getIsRefreshing = createSelector(
    auth,
    (state: IAuthState) => state.isRefreshing
);

export const getCurrentUser = createSelector(
    auth,
    (state: IAuthState) => state.currentUser
);

const getPermissions = (state: IAuthState, countryId: number) => {
    const userPermissions = state.currentUser?.countryPermissions.find(
        (country) => country.countryId === countryId
    );

    return userPermissions;
};

const getCurrentCountry = (countryState: ICountriesState) =>
    countryState.currentCountry ?? 0;

export const getCurrentUserDepositsPermission = createSelector(
    auth,
    countries,
    (state, countryState) => {
        const getPermission = getPermissions(
            state,
            getCurrentCountry(countryState)
        );
        return getPermission?.permissions.deposits;
    }
);

export const getCurrentUserCompaniesPermission = createSelector(
    auth,
    countries,
    (state, countryState) => {
        const getPermission = getPermissions(
            state,
            getCurrentCountry(countryState)
        );
        return getPermission?.permissions.companies;
    }
);

export const getCurrentUserReportsPermission = createSelector(
    auth,
    countries,
    (state, countryState) => {
        const getPermission = getPermissions(
            state,
            getCurrentCountry(countryState)
        );
        return getPermission?.permissions.reports;
    }
);

export const getCurrentUserTransactionsPermission = createSelector(
    auth,
    countries,
    (state, countryState) => {
        const getPermission = getPermissions(
            state,
            getCurrentCountry(countryState)
        );
        return getPermission?.permissions.transactions;
    }
);

export const getCurrentUserSettingsPermission = createSelector(
    auth,
    countries,
    (state, countryState) => {
        const getPermission = getPermissions(
            state,
            getCurrentCountry(countryState)
        );
        return getPermission?.permissions.settings;
    }
);

export const getCurrentUserContactCenterPermission = createSelector(
    auth,
    countries,
    (state, countryState) => {
        const getPermission = getPermissions(
            state,
            getCurrentCountry(countryState)
        );
        return getPermission?.permissions.contact_center;
    }
);
