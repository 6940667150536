import {Action} from 'redux';

// custom
import {TagsActionTypes} from 'redux/action-types/tags';
import {ITag} from 'types/tag-types';

export interface IPatchTagSuccessAction
    extends Action<TagsActionTypes.PATCH_TAG_SUCCESS> {
    payload: ITag[];
}

export const patchTagSuccessAction = (
    data: ITag[]
): IPatchTagSuccessAction => ({
    type: TagsActionTypes.PATCH_TAG_SUCCESS,
    payload: data,
});
