import moment, {Moment} from 'moment';
import 'moment/locale/es';
import {includes} from 'lodash';
import qs from 'query-string';
import XLSX from 'xlsx';

// customs
import {
    AnalyticRanges,
    IFraudDataItemCountries,
    TransactionDepositStatus,
} from 'types/transactions-type';
import {SelectProps} from 'types';
import {ICountry} from 'types/country-types';

export const tableSorter = (a: object, b: object, key: string) => {
    if (
        typeof a[key as keyof typeof a] === 'number' &&
        typeof b[key as keyof typeof b] === 'number'
    ) {
        return a[key as keyof typeof a] - b[key as keyof typeof b];
    }

    if (
        typeof a[key as keyof typeof a] === 'string' &&
        typeof b[key as keyof typeof b] === 'string'
    ) {
        a = a[key as keyof typeof a];
        b = b[key as keyof typeof b];

        if (a > b) return -1;
        if (b > a) return 1;

        return 0;
    }

    return 0;
};

export const parseDate = (
    date: string,
    locale: string,
    onlyDate: boolean = false
) => {
    if (!date) return '--';
    return moment(date)
        .locale(locale)
        .format(onlyDate ? 'LL' : 'LLL');
};

export const parseErrors = (error: any) => {
    let data: object = {};

    if (!!error.response) {
        data = {
            data: error.response.data,
            status: error.response.status,
        };
    }

    return {
        ...data,
        error,
    };
};

export const getStatusClass = (statusText: string) => {
    let className = 'text-success';

    if (includes(['failed', 'cancelled', 'rejected'], statusText)) {
        className = 'text-danger';
    }
    if (includes(['pending'], statusText)) {
        className = 'text-info';
    }
    if (includes(['draft'], statusText)) {
        className = 'text-warning';
    }

    return className;
};

export const getStatusTag = (statusText: string) => {
    let tagColor = 'cyan';

    if (includes(['failed', 'cancelled', 'rejected'], statusText)) {
        tagColor = 'red';
    }
    if (includes(['pending'], statusText)) {
        tagColor = 'orange';
    }
    if (includes(['draft'], statusText)) {
        tagColor = 'geekblue';
    }

    return tagColor;
};

export const getTransactionDepositStatusTag = (
    status: TransactionDepositStatus | null
) => {
    const statusText = status?.toLowerCase() || '';
    let tagColor = 'purple';

    const tagColors: {[key: string]: string} = {
        approved: 'cyan',
        rejected: 'red',
        needs_investigation: 'orange',
        on_hold: 'geekblue',
    };

    if (tagColors[statusText]) {
        tagColor = tagColors[statusText];
    }

    return tagColor;
};

export const getTransactionTypeTag = (type: string) => {
    const typeString = type?.toLowerCase() || '';
    const tagColors: {[key: string]: string} = {
        link: 'blue',
        bitcoin: 'gold',
    };
    let tagColorSelected = 'cyan';

    if (tagColors[typeString]) {
        tagColorSelected = tagColors[typeString];
    }

    return tagColorSelected;
};

export const getFileDownloadLink = (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    return link.click();
};

export const changeWindowLocationUrlPath = (newUrl: string) => {
    return window.history.pushState({path: newUrl}, '', newUrl);
};

export const companyTypes = [
    {
        label: 'Society',
        value: 'Society',
    },
    {
        label: 'Natural Person',
        value: 'Natural person',
    },
];

export const shortAnswers = [
    {
        label: 'yes',
        value: 'yes',
    },
    {
        label: 'no',
        value: 'no',
    },
];

export const KYCTypes = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
];

export const analyticsRange: {
    label: AnalyticRanges;
    value: AnalyticRanges;
}[] = [
    {
        label: 'hour',
        value: 'hour',
    },
    {
        label: 'day',
        value: 'day',
    },
    {
        label: 'week',
        value: 'week',
    },
    {
        label: 'month',
        value: 'month',
    },
];

export const getExcelFile = (
    data: any = [],
    fileName: string = 'File',
    fileType: string = 'xlsx'
) => {
    //* convert state to workbook
    let wb = XLSX.utils.book_new();
    data.map((item: any) => {
        let ws = XLSX.utils.aoa_to_sheet(item.data);
        return XLSX.utils.book_append_sheet(wb, ws, item.bookName);
    });
    //* generate XLSX file and send to client

    return XLSX.writeFile(wb, `${fileName}.${fileType}`);
};

export const getFraudDataItemCountry = (
    item: IFraudDataItemCountries | null | undefined
) => {
    let itemString: string = '--';

    if (!item) return itemString;

    if (item.es) {
        itemString = item.es;
    } else if (item.en) {
        itemString = item.en;
    } else if (item.fr) {
        itemString = item.fr;
    }

    return itemString;
};

export const generalSeletcOptions: SelectProps[] = [
    {
        label: 'yes',
        value: 'yes',
    },
    {
        label: 'no',
        value: 'no',
    },
    {
        label: 'all',
        value: 'all',
    },
];

export const getParsedValueToStringOrNumber = (
    value: number | string
): number | string | boolean => {
    const numberValue = Number(value);
    const parsedValue = isNaN(numberValue) ? value : numberValue;

    if (typeof parsedValue === 'string') {
        return parsedValue.toLowerCase() === 'true'
            ? true
            : parsedValue.toLowerCase() === 'false'
            ? false
            : parsedValue;
    }

    return parsedValue;
};

export const parseQueryParamsValues = (value: string | string[] | null) => {
    if (value) {
        if (Array.isArray(value)) {
            const arrayValues: (number | string | boolean)[] = [];

            value.map((val: string) => {
                const parsedValue = getParsedValueToStringOrNumber(val);
                return arrayValues.push(parsedValue);
            });

            return arrayValues;
        } else {
            const parsedValue = getParsedValueToStringOrNumber(value);
            return parsedValue;
        }
    }

    return null;
};

export const parseMomentRangeToString = (rangeDate: Moment[]) => {
    return [
        moment(rangeDate[0]).format('YYYY-MM-DD'),
        moment(rangeDate[1]).format('YYYY-MM-DD'),
    ];
};

// TODO delete
export const getLocalCountry = (countries: ICountry[]) => {
    const localCountry = countries.find(
        (country) => country.iso2.toUpperCase() === 'SV'
    );

    return localCountry?.id || countries[0].id;
};

export const convertFileToBlob = async (file: File, fileType: string) => {
    const buff = await file.arrayBuffer();
    const array = new Uint8Array(buff);
    const blobData = new Blob([array], {
        type: fileType,
    });

    return blobData;
};

export const isValueNumber = (value: string | number) => !isNaN(+value);

export const setQueryParamsToUrl = <T = any>(params: T) => {
    const queryParams = qs.stringify(params);

    return window.history.pushState(null, '', `?${queryParams}`);
};
