import {Action} from 'redux';

// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {IPagination} from 'types';
import {IUser} from 'types/user-types';

export interface IUsersData {
    data: IUser[];
    pagination: IPagination;
}

export interface IGetUsersSuccessAction
    extends Action<UsersActionTypes.GET_USERS_SUCCESS> {
    payload: IUsersData;
}

export const getUsersSuccessAction = (
    data: IUsersData
): IGetUsersSuccessAction => ({
    type: UsersActionTypes.GET_USERS_SUCCESS,
    payload: data,
});
