// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {RequestError, ReduxError} from 'types';

export const postLoginErrorAction = (
  error: RequestError
): ReduxError<AuthActionTypes.POST_LOGIN_ERROR> => ({
  type: AuthActionTypes.POST_LOGIN_ERROR,
  payload: error,
});
