import {Reducer} from 'redux';

// custom
import {IAuthState} from 'types/auth-types';
import {AuthActions} from 'redux/actions/auth';
import {AuthActionTypes} from 'redux/action-types/auth';

const AuthInitialState: IAuthState = {
    isLogged: false,
    loading: false,
    isRefreshing: false,
    token: null,
    error: null,
    refresh: null,
    currentUser: null,
};

export const AuthReducer: Reducer<IAuthState, AuthActions> = (
    state = AuthInitialState,
    action
) => {
    switch (action.type) {
        case AuthActionTypes.POST_LOGIN_REQUEST:
            return {...state, error: null, loading: true};
        case AuthActionTypes.POST_LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                error: null,
                isLogged: true,
                loading: false,
            };
        case AuthActionTypes.POST_LOGIN_ERROR:
            return {...state, error: action.payload, loading: false};
        case AuthActionTypes.POST_REFRESH_TOKEN_REQUEST:
            return {...state, error: null, isRefreshing: true};
        case AuthActionTypes.POST_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                error: null,
                isRefreshing: false,
            };
        case AuthActionTypes.POST_REFRESH_TOKEN_ERROR:
            return {...state, error: action.payload, isRefreshing: false};
        case AuthActionTypes.GET_CURRENT_USER_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case AuthActionTypes.GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...action.payload,
                },
                error: null,
                loading: false,
            };
        case AuthActionTypes.GET_CURRENT_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case AuthActionTypes.LOGOUT:
            return AuthInitialState;
        default:
            return state;
    }
};
