import {Reducer} from 'redux';

// custom
import {IDepositsState} from 'types/deposits-types';
import {DepositsActions} from 'redux/actions/deposits';
import {DepositsActionTypes} from 'redux/action-types/deposits';
import {AuthActionTypes} from 'redux/action-types/auth';

const depositsInitialState: IDepositsState = {
    data: [],
    error: null,
    loading: false,
    loadingUpdate: false,
    pagination: {
        page: 1,
        perPage: 15,
        total: 0,
    },
    detail: {
        list: [],
        pagination: {
            page: 1,
            perPage: 15,
            total: 0,
        },
    },
};

export const DepositsReducer: Reducer<IDepositsState, DepositsActions> = (
    state = depositsInitialState,
    action
) => {
    switch (action.type) {
        case DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_REQUEST:
        case DepositsActionTypes.GET_DEPOSIT_DETAIL_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: {...state.pagination, ...action.payload.pagination},
                error: null,
                loading: false,
            };
        case DepositsActionTypes.GET_DEPOSIT_DETAIL_SUCCESS:
            return {
                ...state,
                detail: {
                    list: action.payload.data,
                    pagination: {
                        ...state.detail.pagination,
                        ...action.payload.pagination,
                    },
                },
                error: null,
                loading: false,
            };
        case DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_ERROR:
        case DepositsActionTypes.GET_DEPOSIT_DETAIL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DepositsActionTypes.POST_DEPOSIT_BY_COUNTRY_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case DepositsActionTypes.POST_DEPOSIT_BY_COUNTRY_SUCCESS:
            return {
                ...state,
                error: null,
                loadingUpdate: false,
            };
        case DepositsActionTypes.POST_DEPOSIT_BY_COUNTRY_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case DepositsActionTypes.PATCH_DEPOSIT_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case DepositsActionTypes.PATCH_DEPOSIT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                loadingUpdate: false,
            };
        case DepositsActionTypes.PATCH_DEPOSIT_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case AuthActionTypes.LOGOUT:
            return depositsInitialState;
        default:
            return state;
    }
};
