export enum TransactionsActionTypes {
    GET_ANALYTICS_REQUEST = 'GET_ANALYTICS_REQUEST',
    GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS',
    GET_ANALYTICS_ERROR = 'GET_ANALYTICS_ERROR',
    GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST',
    GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS',
    GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR',
    GET_TRANSACTION_INTENTS_REQUEST = 'GET_TRANSACTION_INTENTS_REQUEST',
    GET_TRANSACTION_INTENTS_SUCCESS = 'GET_TRANSACTION_INTENTS_SUCCESS',
    GET_TRANSACTION_INTENTS_ERROR = 'GET_TRANSACTION_INTENTS_ERROR',
    GET_INTENT_REQUEST = 'GET_INTENT_REQUEST',
    GET_INTENT_SUCCESS = 'GET_INTENT_SUCCESS',
    GET_INTENT_ERROR = 'GET_INTENT_ERROR',
    GET_INTENT_NOTES_REQUEST = 'GET_INTENT_NOTES_REQUEST',
    GET_INTENT_NOTES_SUCCESS = 'GET_INTENT_NOTES_SUCCESS',
    GET_INTENT_NOTES_ERROR = 'GET_INTENT_NOTES_ERROR',
    POST_INTENT_NOTE_REQUEST = 'POST_INTENT_NOTE_REQUEST',
    POST_INTENT_NOTE_SUCCESS = 'POST_INTENT_NOTE_SUCCESS',
    POST_INTENT_NOTE_ERROR = 'POST_INTENT_NOTE_ERROR',
    PATCH_TRANSACTION_DEPOSIT_STATUS_REQUEST = 'PATCH_TRANSACTION_DEPOSIT_STATUS_REQUEST',
    PATCH_TRANSACTION_DEPOSIT_STATUS_SUCCESS = 'PATCH_TRANSACTION_DEPOSIT_STATUS_SUCCESS',
    PATCH_TRANSACTION_DEPOSIT_STATUS_ERROR = 'PATCH_TRANSACTION_DEPOSIT_STATUS_ERROR',
    DELETE_TRANSACTIONS_REQUEST = 'DELETE_TRANSACTIONS_REQUEST',
    DELETE_TRANSACTIONS_SUCCESS = 'DELETE_TRANSACTIONS_SUCCESS',
    DELETE_TRANSACTIONS_ERROR = 'DELETE_TRANSACTIONS_ERROR',
}
