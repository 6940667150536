import {Action} from 'redux';

// custom
import {ThemeActionTypes} from 'redux/action-types/theme';

export interface ISetLocaleAction extends Action<ThemeActionTypes.SET_LOCALE> {
  payload: string;
}

export const setLocaleAction = (locale: string): ISetLocaleAction => ({
  type: ThemeActionTypes.SET_LOCALE,
  payload: locale,
});
