// custom
import {CountriesActionTypes} from 'redux/action-types/countries';
import {RequestError, ReduxError} from 'types';

export const getCountriesErrorAction = (
  error: RequestError
): ReduxError<CountriesActionTypes.GET_COUNTRIES_ERROR> => ({
  type: CountriesActionTypes.GET_COUNTRIES_ERROR,
  payload: error,
});
