import {IGetDepositsByCountryParams} from 'redux/actions/deposits/getDepositsByCountryRequestAction';
import {IGetDepositDetailParams} from 'redux/actions/deposits/getDepositDetailRequestAction';
import {IPostDepositByCountryParams} from 'redux/actions/deposits/postDepositByCountryRequestAction';
import {PatchDepositParams} from 'redux/actions/deposits/patchDepositRequestAction';

import {request, Methods, withToken, RequestOptions} from 'utils/request';

export const getDepositsByCountryRequest = async (
    params: IGetDepositsByCountryParams
) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request('/cms/reports', options);
};

export const getDepositDetailRequest = async ({
    identifier,
    ...params
}: IGetDepositDetailParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request(`/cms/reports/${identifier}/deposits`, options);
};

export const postDepositByCountryRequest = async (
    data: IPostDepositByCountryParams
) => {
    const options: RequestOptions = await withToken({
        method: Methods.POST,
        data,
    });

    return request('/cms/reports/deposits', options);
};

export const patchDepositItemRequest = async ({
    reportIdentifier,
    identifier,
    ...data
}: PatchDepositParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(
        `/cms/reports/${reportIdentifier}/deposits/${identifier}`,
        options
    );
};
