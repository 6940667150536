export enum AuthActionTypes {
    POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST',
    POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS',
    POST_LOGIN_ERROR = 'POST_LOGIN_ERROR',
    POST_REFRESH_TOKEN_REQUEST = 'POST_REFRESH_TOKEN_REQUEST',
    POST_REFRESH_TOKEN_SUCCESS = 'POST_REFRESH_TOKEN_SUCCESS',
    POST_REFRESH_TOKEN_ERROR = 'POST_REFRESH_TOKEN_ERROR',
    GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST',
    GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',
    GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR',
    LOGOUT = 'LOGOUT',
}
