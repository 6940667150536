import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {ICompanyKYC} from 'types/company-types';

export interface IGetCompanyKYCSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_KYC_SUCCESS> {
    payload: ICompanyKYC;
}

export const getCompanyKYCSuccessAction = (
    data: ICompanyKYC
): IGetCompanyKYCSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_KYC_SUCCESS,
    payload: data,
});
