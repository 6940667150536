// sagas
import {authSagas} from './auth';
import {countriesSagas} from './countries';
import {banksSagas} from './banks';
import {depositsSagas} from './deposits';
import {companiesSagas} from './companies';
import {categoriesSagas} from './categories';
import {transactionsSagas} from './transactions';
import {tagsSagas} from './tags';
import {usersSagas} from './users';
import {sagaErrors} from './errors';

export const sagas = [
    authSagas,
    countriesSagas,
    sagaErrors,
    banksSagas,
    depositsSagas,
    companiesSagas,
    categoriesSagas,
    transactionsSagas,
    tagsSagas,
    usersSagas,
];
