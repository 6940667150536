import {Action} from 'redux';

// custom
import {CategoriesActionTypes} from 'redux/action-types/categories';
import {ICategory} from 'types/category-types';

export interface ICategoriesData {
    data: ICategory[];
}

export interface IGetCategoriesSuccessAction
    extends Action<CategoriesActionTypes.GET_CATEGORIES_SUCCESS> {
    payload: ICategory[];
}

export const getCategoriesSuccessAction = (
    data: ICategory[]
): IGetCategoriesSuccessAction => ({
    type: CategoriesActionTypes.GET_CATEGORIES_SUCCESS,
    payload: data,
});
