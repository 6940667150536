// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {ReduxError, RequestError} from 'types';

export const getTransactionIntentsErrorAction = (
    error: RequestError
): ReduxError<TransactionsActionTypes.GET_TRANSACTION_INTENTS_ERROR> => ({
    type: TransactionsActionTypes.GET_TRANSACTION_INTENTS_ERROR,
    payload: error,
});
