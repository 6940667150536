import {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

const Banks = lazy(() => import('pages/dashboard/settings/banks'));
const Countries = lazy(() => import('pages/dashboard/settings/countries'));
const Tags = lazy(() => import('pages/dashboard/settings/tags'));
const Users = lazy(() => import('pages/dashboard/settings/users'));

const SettingsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/settings/banks" component={Banks} />
            <Route exact path="/settings/countries" component={Countries} />
            <Route exact path="/settings/tags" component={Tags} />
            <Route exact path="/settings/users" component={Users} />
        </Switch>
    );
};

export default SettingsRoutes;
