// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {RequestError, ReduxError} from 'types';

export const getCurrentUserErrorAction = (
    error: RequestError
): ReduxError<AuthActionTypes.GET_CURRENT_USER_ERROR> => ({
    type: AuthActionTypes.GET_CURRENT_USER_ERROR,
    payload: error,
});
