import {takeLatest, call, put} from 'redux-saga/effects';
import get from 'lodash/get';
import {AxiosResponse} from 'axios';

// custom
import {parseErrors} from 'utils';

// action types
import {CategoriesActionTypes} from 'redux/action-types/categories';

// requests
import {getCategoriesRequest} from 'services/categories';

// actions
import {getCategoriesSuccessAction} from 'redux/actions/categories/getCategoriesSuccesstAction';
import {getCategoriesErrorAction} from 'redux/actions/categories/getCategoriesErrorAction';

// types
import {IGetCategoriesRequestAction} from 'redux/actions/categories/getCategoriesRequestAction';
import {RequestError} from 'types';
import {ICategory} from 'types/category-types';

function* getCategories(action: IGetCategoriesRequestAction) {
    try {
        const result: AxiosResponse = yield call(getCategoriesRequest);

        const data: ICategory[] = get(result.data, 'data', []);

        yield put(getCategoriesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCategoriesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* categoriesSagas() {
    yield takeLatest(
        CategoriesActionTypes.GET_CATEGORIES_REQUEST,
        getCategories
    );
}
