import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {RequestError} from 'types';

export interface IPostRefreshTokenRequestAction
  extends Action<AuthActionTypes.POST_REFRESH_TOKEN_REQUEST> {
  callback?: (error?: RequestError | any, data?: unknown) => void;
}

export const postRefreshTokenRequestAction = (
  callback?: (error?: RequestError | any, data?: unknown) => void
): IPostRefreshTokenRequestAction => ({
  type: AuthActionTypes.POST_REFRESH_TOKEN_REQUEST,
  callback,
});
