import React, {useEffect, useState} from 'react';

import {Alert, AlertProps, Button, Card, Col, Form, Input, Row} from 'antd';
import {motion} from 'framer-motion';
import {useIntl} from 'react-intl';
import {NavLink, RouteComponentProps} from 'react-router-dom';
// types
import {CommonProps} from 'types';
import {API_URL} from 'utils/request';

import {MailOutlined} from '@ant-design/icons';

import {FormStyled} from '../sign-in/styled';

export type ForgotPasswordProps = RouteComponentProps &
    CommonProps & {
        isLogged: boolean;
    };

export interface IForgotPasswordFields {
    email: string;
}

const FormItem = Form.Item;

const ForgotPassword = ({isLogged, history}: ForgotPasswordProps) => {
    useEffect(() => {
        if (isLogged) {
            return history.replace({pathname: '/'});
        }
    }, [isLogged, history]);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setErrorMessage('');
                setShowMessage(false);
            }, 5000);
        }
    });

    const intl = useIntl();
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState<AlertProps['type']>('success');
    const [loading, setLoading] = useState(false);
    const initialValues: IForgotPasswordFields = {
        email: '',
    };

    const onSubmit = async (values: IForgotPasswordFields) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        };

        const errorMessage = 'Parece que hubo un error, intente de nuevo';
        const successMessage =
            'Hemos enviado un correo con las instrucciones para restablecer tu contraseña';

        try {
            setLoading(true);
            const data = await fetch(`${API_URL}auth/password`, options);
            if (data.ok) {
                setErrorType('success');
                setErrorMessage(successMessage);
                setShowMessage(true);
            } else {
                const response = await data.json();
                setErrorType('error');
                setErrorMessage(response?.message[0] || errorMessage);
                setShowMessage(true);
            }

            setLoading(false);
        } catch (error) {
            setErrorType('error');
            setErrorMessage(errorMessage);
            setShowMessage(true);
            setLoading(false);
        }
    };

    return (
        <div className="auth-container">
            <FormStyled className="h-100">
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col xs={20} sm={20} md={20} lg={7}>
                            <Card>
                                <div className="mt-4 mb-2">
                                    <div className="text-center">
                                        <picture>
                                            <source
                                                srcSet="/img/cubo-logo.webp"
                                                type="image/webp"
                                            />
                                            <source
                                                srcSet="/img/cubo-logo.png"
                                                type="image/png"
                                            />
                                            <img
                                                src="/img/cubo-logo.png"
                                                alt="Cubo Logo"
                                                className="img-fluid"
                                            />
                                        </picture>
                                    </div>
                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <motion.div
                                                initial={{
                                                    opacity: 0,
                                                    marginBottom: 0,
                                                    marginTop: 0,
                                                }}
                                                animate={{
                                                    opacity: showMessage
                                                        ? 1
                                                        : 0,
                                                    marginTop: showMessage
                                                        ? 20
                                                        : 0,
                                                    marginBottom: showMessage
                                                        ? 20
                                                        : 0,
                                                }}
                                            >
                                                <Alert
                                                    type={errorType}
                                                    showIcon
                                                    message={errorMessage}
                                                />
                                            </motion.div>

                                            <Form
                                                layout="vertical"
                                                initialValues={initialValues}
                                                onFinish={onSubmit}
                                            >
                                                <FormItem
                                                    name="email"
                                                    label={intl.formatMessage({
                                                        id: 'common.labels.email',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                intl.formatMessage(
                                                                    {
                                                                        id: 'common.validation.email.required',
                                                                    }
                                                                ),
                                                        },
                                                        {
                                                            type: 'email',
                                                            message:
                                                                intl.formatMessage(
                                                                    {
                                                                        id: 'common.validation.email.format',
                                                                    }
                                                                ),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={
                                                            <MailOutlined className="text-primary" />
                                                        }
                                                    />
                                                </FormItem>

                                                <FormItem className="text-center">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        block
                                                        loading={loading}
                                                        className="mb-3"
                                                    >
                                                        Recuperar Contraseña
                                                    </Button>
                                                    <NavLink
                                                        to="/signin"
                                                        className="cursor-pointer font-size-sm font-weight-normal text-muted"
                                                    >
                                                        Regresar al login
                                                    </NavLink>
                                                </FormItem>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </FormStyled>
        </div>
    );
};

export default ForgotPassword;
