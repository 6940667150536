import {createSelector} from 'reselect';

// custom
import {IStoreState} from 'types';
import {IThemeState} from 'types/theme-types';

const theme = (state: IStoreState) => state.theme;

export const getLocale = createSelector(
  theme,
  (state: IThemeState) => state.locale
);

export const isNavCollapse = createSelector(
  theme,
  (state: IThemeState) => state.navCollapsed
);
