import {Action} from 'redux';

// custom
import {ThemeActionTypes} from 'redux/action-types/theme';

export interface IToggleNav extends Action<ThemeActionTypes.TOGGLE_NAV> {
  payload: boolean;
}

export const toggleNavAction = (isNavCollapse: boolean): IToggleNav => ({
  type: ThemeActionTypes.TOGGLE_NAV,
  payload: isNavCollapse,
});
