import {lazy} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

const Home = lazy(() => import('pages/dashboard/home'));

const HomeRoute = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            {/* <Redirect from="*" to="/404" push={false} /> */}
            <Redirect from="*" to="/" push={false} />
        </Switch>
    );
};

export default HomeRoute;
