import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {IPagination} from 'types';
import {ICompany} from 'types/company-types';

export interface ICompaniesData {
    data: ICompany[];
    pagination: IPagination;
}

export interface IGetCompaniesSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANIES_SUCCESS> {
    payload: ICompaniesData;
}

export const getCompaniesSuccessAction = (
    data: ICompaniesData
): IGetCompaniesSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANIES_SUCCESS,
    payload: data,
});
