import {Action} from 'redux';

// custom
import {TransactionsActionTypes} from 'redux/action-types/transactions';
import {IPagination} from 'types';
import {ITransaction} from 'types/transactions-type';

export interface ITransactionsData {
    data: ITransaction[];
    pagination: IPagination;
}

export interface IGetTransactionsSuccessAction
    extends Action<TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS> {
    payload: ITransactionsData;
}

export const getTransactionsSuccessAction = (
    data: ITransactionsData
): IGetTransactionsSuccessAction => ({
    type: TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS,
    payload: data,
});
