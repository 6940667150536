// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {RequestError, ReduxError} from 'types';

export const getUsersErrorAction = (
    error: RequestError
): ReduxError<UsersActionTypes.GET_USERS_ERROR> => ({
    type: UsersActionTypes.GET_USERS_ERROR,
    payload: error,
});
