import { Reducer } from 'redux';
import { AuthActionTypes } from 'redux/action-types/auth';
import { UsersActionTypes } from 'redux/action-types/users';
// custom
import { UsersActions } from 'redux/actions/users';
import { IUsersState } from 'types/user-types';

const usersInitialState: IUsersState = {
    data: [],
    error: null,
    loading: false,
    loadingUpdate: false,
    roles: {
        loading: false,
        error: null,
        data: [],
    },
    pagination: {
        page: 1,
        perPage: 15,
        total: 0,
    },
};

export const UsersReducer: Reducer<IUsersState, UsersActions> = (
    state = usersInitialState,
    action
) => {
    switch (action.type) {
        case UsersActionTypes.GET_USER_ROLES_REQUEST:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    error: null,
                    loading: true,
                },
            };
        case UsersActionTypes.GET_USER_ROLES_SUCCESS:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    error: null,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case UsersActionTypes.GET_USER_ROLES_ERROR:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    error: action.payload,
                    loading: false,
                },
            };
        case UsersActionTypes.GET_USERS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case UsersActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: {...state.pagination, ...action.payload.pagination},
                error: null,
                loading: false,
            };
        case UsersActionTypes.GET_USERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UsersActionTypes.POST_USER_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case UsersActionTypes.POST_USER_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                error: null,
                loadingUpdate: false,
            };
        case UsersActionTypes.POST_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case UsersActionTypes.PATCH_USER_REQUEST:
        case UsersActionTypes.DELETE_USER_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case UsersActionTypes.PATCH_USER_SUCCESS:
        case UsersActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                loadingUpdate: false,
            };
        case UsersActionTypes.PATCH_USER_ERROR:
        case UsersActionTypes.DELETE_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case AuthActionTypes.LOGOUT:
            return usersInitialState;
        default:
            return state;
    }
};
