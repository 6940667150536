import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IPatchCompanyOwnerSuccessAction
    extends Action<CompaniesActionTypes.PATCH_COMPANY_OWNER_SUCCESS> {
    payload: any;
}

export const patchCompanyOwnerSuccessAction = (
    data: any
): IPatchCompanyOwnerSuccessAction => ({
    type: CompaniesActionTypes.PATCH_COMPANY_OWNER_SUCCESS,
    payload: data,
});
