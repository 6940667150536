export enum UsersActionTypes {
    GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST',
    GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS',
    GET_USER_ROLES_ERROR = 'GET_USER_ROLES_ERROR',
    GET_USERS_REQUEST = 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
    GET_USERS_ERROR = 'GET_USERS_ERROR',
    POST_USER_REQUEST = 'POST_USER_REQUEST',
    POST_USER_SUCCESS = 'POST_USER_SUCCESS',
    POST_USER_ERROR = 'POST_USER_ERROR',
    PATCH_USER_REQUEST = 'PATCH_USER_REQUEST',
    PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS',
    PATCH_USER_ERROR = 'PATCH_USER_ERROR',
    DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR = 'DELETE_USER_ERROR',
}
