import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
// action types
import { CountriesActionTypes } from 'redux/action-types/countries';
import {
  getCountriesErrorAction,
} from 'redux/actions/countries/getCountriesErrorAction';
// types
import {
  IGetCountriesRequestAction,
} from 'redux/actions/countries/getCountriesRequestAction';
// actions
import {
  getCountriesSuccessAction,
  ICountriesData,
} from 'redux/actions/countries/getCountriesSuccesstAction';
import {
  patchCountryErrorAction,
} from 'redux/actions/countries/patchCountryErrorAction';
import {
  IPatchCountryRequestAction,
} from 'redux/actions/countries/patchCountryRequestAction';
import {
  patchCountrySuccessAction,
} from 'redux/actions/countries/patchCountrySuccessAction';
// requests
import {
  getCountriesRequest,
  patchCountryRequest,
} from 'services/countries';
import {
  IPagination,
  IStoreState,
  RequestError,
} from 'types';
import { ICountry } from 'types/country-types';
// custom
import {
  getLocalCountry,
  parseErrors,
} from 'utils';

function* getCountries(action: IGetCountriesRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            getCountriesRequest,
            action.payload
        );

        const requestData = get(result.data, 'data', []);
        const pagination: IPagination = get(result.data, 'pagination', {});
        const countriesData: ICountry[] = requestData || [];
        const getCurrentCountry = getLocalCountry(countriesData);

        const data: ICountriesData = {
            data: requestData,
            pagination: {page: pagination.page, total: pagination.total},
            currentCountry: getCurrentCountry,
        };

        yield put(getCountriesSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCountriesErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* patchCountry(action: IPatchCountryRequestAction) {
    const {
        countries: {data: countriesList},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            patchCountryRequest,
            action.payload
        );
        const dataUpdated: ICountry = get(result.data, 'data', {});
        const currentCountryIndex = countriesList.findIndex(
            (item: ICountry) => item.id === dataUpdated.id
        );

        countriesList[currentCountryIndex] = dataUpdated;

        yield put(patchCountrySuccessAction(countriesList));

        action.callback && action.callback(null, dataUpdated);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(patchCountryErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* countriesSagas() {
    yield takeLatest(CountriesActionTypes.GET_COUNTRIES_REQUEST, getCountries);
    yield takeLatest(CountriesActionTypes.PATCH_COUNTRY_REQUEST, patchCountry);
}
