import {ISignInFields} from 'pages/auth/sign-in';
import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {RequestError} from 'types';

export interface IPostLoginRequestAction
  extends Action<AuthActionTypes.POST_LOGIN_REQUEST> {
  payload: ISignInFields;
  callback: (error?: RequestError | any, data?: unknown) => void;
}

export const postLoginRequestAction = (
  data: ISignInFields,
  callback: (error?: RequestError | any, data?: unknown) => void
): IPostLoginRequestAction => ({
  type: AuthActionTypes.POST_LOGIN_REQUEST,
  payload: data,
  callback,
});
