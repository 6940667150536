import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {IPagination} from 'types';
import {ICompanyNotes} from 'types/company-types';

export interface ICompanyNotesData {
    data: ICompanyNotes[];
    pagination: IPagination;
}

export interface IGetCompanyNotesSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_NOTES_SUCCESS> {
    payload: ICompanyNotesData;
}

export const getCompanyNotesSuccessAction = (
    data: ICompanyNotesData
): IGetCompanyNotesSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_NOTES_SUCCESS,
    payload: data,
});
