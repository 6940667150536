import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {IPagination} from 'types';
import {ICompanyDeposit} from 'types/company-types';

export interface ICompanyDepositsData {
    data: ICompanyDeposit[];
    pagination: IPagination;
}

export interface IGetCompanyDepositsSuccessAction
    extends Action<CompaniesActionTypes.GET_COMPANY_DEPOSITS_SUCCESS> {
    payload: ICompanyDepositsData;
}

export const getCompanyDepositsSuccessAction = (
    data: ICompanyDepositsData
): IGetCompanyDepositsSuccessAction => ({
    type: CompaniesActionTypes.GET_COMPANY_DEPOSITS_SUCCESS,
    payload: data,
});
