import React from 'react';

import ForgotPassword from 'pages/auth/forgot-password';
import SignIn from 'pages/auth/sign-in';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

export const AuthRoutes: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Redirect to="/signin" />
        </Switch>
    );
};
