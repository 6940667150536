export enum TagsActionTypes {
    GET_TAGS_REQUEST = 'GET_TAGS_REQUEST',
    GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',
    GET_TAGS_ERROR = 'GET_TAGS_ERROR',
    POST_TAG_REQUEST = 'POST_TAG_REQUEST',
    POST_TAG_SUCCESS = 'POST_TAG_SUCCESS',
    POST_TAG_ERROR = 'POST_TAG_ERROR',
    PATCH_TAG_REQUEST = 'PATCH_TAG_REQUEST',
    PATCH_TAG_SUCCESS = 'PATCH_TAG_SUCCESS',
    PATCH_TAG_ERROR = 'PATCH_TAG_ERROR',
    DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST',
    DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS',
    DELETE_TAG_ERROR = 'DELETE_TAG_ERROR',
}
