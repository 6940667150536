import {Reducer} from 'redux';

// custom
import {IThemeState} from 'types/theme-types';
import {ThemeActions} from 'redux/actions/theme';
import {ThemeActionTypes} from 'redux/action-types/theme';
import {AuthActionTypes} from 'redux/action-types/auth';

const themeInitialState: IThemeState = {locale: 'es', navCollapsed: false};

export const ThemeReducer: Reducer<IThemeState, ThemeActions> = (
  state = themeInitialState,
  action
) => {
  switch (action.type) {
    case ThemeActionTypes.SET_LOCALE:
      return {...state, locale: action.payload};
    case ThemeActionTypes.TOGGLE_NAV:
      return {...state, navCollapsed: action.payload};
    case AuthActionTypes.LOGOUT:
      return themeInitialState;
    default:
      return state;
  }
};
