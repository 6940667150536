import {ISignInFields} from 'pages/auth/sign-in';

import {request, Methods, withToken, RequestOptions} from 'utils/request';

export const postLoginRequest = (data: ISignInFields) =>
    request('/auth/code', {method: Methods.POST, data});

export const postRefreshTokenRequest = (refreshToken: string | null) =>
    request('/auth/refresh', {method: Methods.POST, data: {refreshToken}});

export const getCurrentUserRequest = async () => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
    });

    return request(`/users/me`, options);
};
