import {Action} from 'redux';

// custom
import {BanksActionTypes} from 'redux/action-types/banks';
import {IBank} from 'types/bank-types';

export interface IPatchBankByCountrySuccessAction
  extends Action<BanksActionTypes.PATCH_BANK_BY_COUNTRY_SUCCESS> {
  payload: IBank[];
}

export const patchBankByCountrySuccessAction = (
  data: IBank[]
): IPatchBankByCountrySuccessAction => ({
  type: BanksActionTypes.PATCH_BANK_BY_COUNTRY_SUCCESS,
  payload: data,
});
