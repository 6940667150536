import {Action} from 'redux';

// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';
// import {IDepositUpdate} from 'types/deposits-types';

export interface IPatchDepositSuccessAction
    extends Action<DepositsActionTypes.PATCH_DEPOSIT_SUCCESS> {
    payload: any;
}

export const patchDepositSuccessAction = (
    data: any
): IPatchDepositSuccessAction => ({
    type: DepositsActionTypes.PATCH_DEPOSIT_SUCCESS,
    payload: data,
});
