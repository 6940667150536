import enUs from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';

import {en} from './entries/en_US';
import {es} from './entries/es_ES';

export type LocalesSupported = 'es' | 'en';

export const locales = {
  es: {...es, antd: esES},
  en: {...en, antd: enUs},
};
