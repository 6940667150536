export enum CompaniesActionTypes {
    GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST',
    GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS',
    GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR',
    GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST',
    GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS',
    GET_COMPANY_ERROR = 'GET_COMPANY_ERROR',
    GET_COMPANY_AML_REQUEST = 'GET_COMPANY_AML_REQUEST',
    GET_COMPANY_AML_SUCCESS = 'GET_COMPANY_AML_SUCCESS',
    GET_COMPANY_AML_ERROR = 'GET_COMPANY_AML_ERROR',
    GET_COMPANY_KYC_REQUEST = 'GET_COMPANY_KYC_REQUEST',
    GET_COMPANY_KYC_SUCCESS = 'GET_COMPANY_KYC_SUCCESS',
    GET_COMPANY_KYC_ERROR = 'GET_COMPANY_KYC_ERROR',
    GET_COMPANY_TRANSACTIONS_REQUEST = 'GET_COMPANY_TRANSACTIONS_REQUEST',
    GET_COMPANY_TRANSACTIONS_SUCCESS = 'GET_COMPANY_TRANSACTIONS_SUCCESS',
    GET_COMPANY_TRANSACTIONS_ERROR = 'GET_COMPANY_TRANSACTIONS_ERROR',
    GET_COMPANY_DEPOSITS_REQUEST = 'GET_COMPANY_DEPOSITS_REQUEST',
    GET_COMPANY_DEPOSITS_SUCCESS = 'GET_COMPANY_DEPOSITS_SUCCESS',
    GET_COMPANY_DEPOSITS_ERROR = 'GET_COMPANY_DEPOSITS_ERROR',
    PATCH_COMPANY_STATUS_REQUEST = 'PATCH_COMPANY_STATUS_REQUEST',
    PATCH_COMPANY_STATUS_SUCCESS = 'PATCH_COMPANY_STATUS_SUCCESS',
    PATCH_COMPANY_STATUS_ERROR = 'PATCH_COMPANY_STATUS_ERROR',
    GET_COMPANIES_REPORT_REQUEST = 'GET_COMPANIES_REPORT_REQUEST',
    GET_COMPANIES_REPORT_SUCCESS = 'GET_COMPANIES_REPORT_SUCCESS',
    GET_COMPANIES_REPORT_ERROR = 'GET_COMPANIES_REPORT_ERROR',
    GET_COMPANIES_TRANSACTIONS_REPORT_REQUEST = 'GET_COMPANIES_TRANSACTIONS_REPORT_REQUEST',
    GET_COMPANIES_TRANSACTIONS_REPORT_SUCCESS = 'GET_COMPANIES_TRANSACTIONS_REPORT_SUCCESS',
    GET_COMPANIES_TRANSACTIONS_REPORT_ERROR = 'GET_COMPANIES_TRANSACTIONS_REPORT_ERROR',
    PATCH_COMPANY_REQUEST = 'PATCH_COMPANY_REQUEST',
    PATCH_COMPANY_SUCCESS = 'PATCH_COMPANY_SUCCESS',
    PATCH_COMPANY_ERROR = 'PATCH_COMPANY_ERROR',
    PATCH_COMPANY_OWNER_REQUEST = 'PATCH_COMPANY_OWNER_REQUEST',
    PATCH_COMPANY_OWNER_SUCCESS = 'PATCH_COMPANY_OWNER_SUCCESS',
    PATCH_COMPANY_OWNER_ERROR = 'PATCH_COMPANY_OWNER_ERROR',
    GET_COMPANY_BILLING_INFORMATION_REQUEST = 'GET_COMPANY_BILLING_INFORMATION_REQUEST',
    GET_COMPANY_BILLING_INFORMATION_SUCCESS = 'GET_COMPANY_BILLING_INFORMATION_SUCCESS',
    GET_COMPANY_BILLING_INFORMATION_ERROR = 'GET_COMPANY_BILLING_INFORMATION_ERROR',
    GET_COMPANY_NOTES_REQUEST = 'GET_COMPANY_NOTES_REQUEST',
    GET_COMPANY_NOTES_SUCCESS = 'GET_COMPANY_NOTES_SUCCESS',
    GET_COMPANY_NOTES_ERROR = 'GET_COMPANY_NOTES_ERROR',
    POST_COMPANY_NOTE_REQUEST = 'POST_COMPANY_NOTE_REQUEST',
    POST_COMPANY_NOTE_SUCCESS = 'POST_COMPANY_NOTE_SUCCESS',
    POST_COMPANY_NOTE_ERROR = 'POST_COMPANY_NOTE_ERROR',
    GET_COMPANY_TAGS_REQUEST = 'GET_COMPANY_TAGS_REQUEST',
    GET_COMPANY_TAGS_SUCCESS = 'GET_COMPANY_TAGS_SUCCESS',
    GET_COMPANY_TAGS_ERROR = 'GET_COMPANY_TAGS_ERROR',
    POST_COMPANY_TAGS_REQUEST = 'POST_COMPANY_TAGS_REQUEST',
    POST_COMPANY_TAGS_SUCCESS = 'POST_COMPANY_TAGS_SUCCESS',
    POST_COMPANY_TAGS_ERROR = 'POST_COMPANY_TAGS_ERROR',
    DELETE_COMPANY_KYC_REQUEST = 'DELETE_COMPANY_KYC_REQUEST',
    DELETE_COMPANY_KYC_SUCCESS = 'DELETE_COMPANY_KYC_SUCCESS',
    DELETE_COMPANY_KYC_ERROR = 'DELETE_COMPANY_KYC_ERROR',
    PATCH_COMPANY_SETTINGS_REQUEST = 'PATCH_COMPANY_SETTINGS_REQUEST',
    PATCH_COMPANY_SETTINGS_SUCCESS = 'PATCH_COMPANY_SETTINGS_SUCCESS',
    PATCH_COMPANY_SETTINGS_ERROR = 'PATCH_COMPANY_SETTINGS_ERROR',
    GET_RELATED_COMPANIES_REQUEST = 'GET_RELATED_COMPANIES_REQUEST',
    GET_RELATED_COMPANIES_SUCCESS = 'GET_RELATED_COMPANIES_SUCCESS',
    GET_RELATED_COMPANIES_ERROR = 'GET_RELATED_COMPANIES_ERROR',
    PATCH_COMPANY_POS_STATUS_REQUEST = 'PATCH_COMPANY_POS_STATUS_REQUEST',
    PATCH_COMPANY_POS_STATUS_SUCCESS = 'PATCH_COMPANY_POS_STATUS_SUCCESS',
    PATCH_COMPANY_POS_STATUS_ERROR = 'PATCH_COMPANY_POS_STATUS_ERROR',
    GET_COMPANY_DOCUMENTS_REQUEST = 'GET_COMPANY_DOCUMENTS_REQUEST',
    GET_COMPANY_DOCUMENTS_SUCCESS = 'GET_COMPANY_DOCUMENTS_SUCCESS',
    GET_COMPANY_DOCUMENTS_ERROR = 'GET_COMPANY_DOCUMENTS_ERROR',
}
