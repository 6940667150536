import {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

const Deposits = lazy(() => import('pages/dashboard/deposits'));
const DepositDetail = lazy(() => import('pages/dashboard/deposits/detail'));

const DepositsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/deposits" component={Deposits} />
            <Route
                exact
                path="/deposits/:reportIdentifier"
                component={DepositDetail}
            />
        </Switch>
    );
};

export default DepositsRoutes;
