// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const patchCompanyStatusErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.PATCH_COMPANY_STATUS_ERROR> => ({
    type: CompaniesActionTypes.PATCH_COMPANY_STATUS_ERROR,
    payload: error,
});
