import {Action} from 'redux';
// custom
import {CountriesActionTypes} from 'redux/action-types/countries';
import {IPagination} from 'types';
import {ICountry} from 'types/country-types';

export interface ICountriesData {
    data: ICountry[];
    pagination: IPagination;
    currentCountry: number | null;
}

export interface IGetCountriesSuccessAction
    extends Action<CountriesActionTypes.GET_COUNTRIES_SUCCESS> {
    payload: ICountriesData;
}

export const getCountriesSuccessAction = (
    data: ICountriesData
): IGetCountriesSuccessAction => ({
    type: CountriesActionTypes.GET_COUNTRIES_SUCCESS,
    payload: data,
});
