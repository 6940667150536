import React from 'react';

import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Select,
} from 'antd';
import supportedLanguages from 'assets/data/language.data.json';
import classnames from 'classnames';
import { useSetCurrentCountry } from 'hooks/common';
import { useFetchCountries } from 'hooks/countries';
import {
  FormattedMessage,
  useIntl,
} from 'react-intl';
import {
  NavLink,
  useHistory,
} from 'react-router-dom';
import { setLocaleAction } from 'redux/actions/theme/setLocaleAction';
// actions
import { toggleNavAction } from 'redux/actions/theme/toggleNavAction';
// types
import { CommonProps } from 'types';
import { ICurrentUser } from 'types/auth-types';
import { ICountry } from 'types/country-types';

import {
  CheckOutlined,
  DownOutlined,
  GlobalOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

// custom
import {
  HeaderWrapper,
  Logo,
  Nav,
  NavLeft,
  NavRight,
} from './styles';

export type HeaderNavProps = CommonProps & {
    navCollapsed: boolean;
    locale: string;
    currentUser: ICurrentUser | null;
    countries: ICountry[];
    currentCountry: Number | null;
    loadingCountries: boolean;
};

type LanguageDropdownProps = CommonProps & {
    locale: string;
};

const Header = Layout.Header;
const MenuItem = Menu.Item;
const {confirm} = Modal;
const {Option} = Select;

const LanguageDropdown = ({locale, dispatch}: LanguageDropdownProps) => {
    const LanguageOptions = () => (
        <Menu>
            {supportedLanguages.map((language, index) => (
                <MenuItem
                    key={index}
                    className={classnames({
                        'ant-dropdown-menu-item-active':
                            locale === language.langId,
                    })}
                    onClick={() => dispatch(setLocaleAction(language.langId))}
                >
                    <span className="d-flex justify-content-between align-items-center">
                        <div>
                            <span className="font-weight-normal ml-2">
                                <FormattedMessage id={language.langName} />
                            </span>
                        </div>

                        {locale === language.langId && (
                            <CheckOutlined className="text-success" />
                        )}
                    </span>
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <Dropdown
            placement="bottomRight"
            overlay={LanguageOptions}
            trigger={['click']}
        >
            <Menu mode="horizontal">
                <MenuItem>
                    <GlobalOutlined className="nav-icon mr-0" />
                </MenuItem>
            </Menu>
        </Dropdown>
    );
};

const ProfileDropdown: React.FC<{user: ICurrentUser | null}> = ({user}) => {
    const intl = useIntl();
    let history = useHistory();
    const onCloseSession = (path: string) => {
        confirm({
            title: intl.formatMessage({
                id: 'navbar.profileDropdown.signOut.question',
            }),
            content: '',
            onOk() {
                return history.push(path);
            },
            onCancel() {},
        });
    };

    const profileDropdownMenu = [
        // {
        //   title: 'navbar.profileDropdown.editProfile.title',
        //   icon: <EditOutlined className="mr-3" />,
        //   path: '/profile/edit',
        // },
        {
            title: 'navbar.profileDropdown.signOut.title',
            icon: <LogoutOutlined className="mr-3" />,
            path: '/signout',
        },
    ];

    const ProfileMenu = () => (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex align-items-center">
                    {/* <Avatar>MT</Avatar> */}

                    <div className="pl-3">
                        <h4 className="mb-0">
                            {user?.firstName || '--'} {user?.lastName || '--'}
                        </h4>
                    </div>
                </div>
            </div>

            <div className="nav-profile-body">
                <Menu>
                    {profileDropdownMenu.map((item, index) => {
                        if (item.path === '/signout') {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => onCloseSession(item.path)}
                                >
                                    {item.icon}

                                    <span className="font-weight-normal">
                                        <FormattedMessage id={item.title} />
                                    </span>
                                </MenuItem>
                            );
                        }
                        return (
                            <MenuItem key={index}>
                                <NavLink to={item.path}>
                                    {item.icon}

                                    <span className="font-weight-normal">
                                        <FormattedMessage id={item.title} />
                                    </span>
                                </NavLink>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        </div>
    );

    return (
        <Dropdown
            placement="bottomRight"
            overlay={ProfileMenu}
            trigger={['click']}
        >
            <Menu className="d-flex" mode="horizontal">
                <MenuItem>
                    <Avatar className="bg-gradient-primary">
                        {user?.firstName?.charAt(0) || '-'}
                        {user?.lastName?.charAt(0) || '-'}
                    </Avatar>
                </MenuItem>
            </Menu>
        </Dropdown>
    );
};

export const HeaderNav: React.FC<HeaderNavProps> = ({
    dispatch,
    navCollapsed,
    locale,
    currentUser,
    countries,
    currentCountry,
    loadingCountries,
}) => {
    useFetchCountries();

    const {handleSetCurrentCountry} = useSetCurrentCountry();

    return (
        <Header className="app-header">
            <HeaderWrapper className="app-header-wrapper">
                <Logo navCollapsed={navCollapsed}>
                    <NavLink to="/">
                        <div className="d-flex justify-content-center align-items-center h-100 text-center">
                            {navCollapsed && (
                                <Avatar src="/img/cubo-logo-small.svg" />
                            )}

                            {!navCollapsed && (
                                <picture>
                                    <source
                                        srcSet="/img/cubo-logo.webp"
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet="/img/cubo-logo.png"
                                        type="image/png"
                                    />
                                    <img
                                        src="/img/cubo-logo.png"
                                        alt="Cubo Pago Logo"
                                        className="navbar-brand-regular"
                                    />
                                </picture>
                            )}
                        </div>
                    </NavLink>
                </Logo>

                <Nav className="nav" navCollapsed={navCollapsed}>
                    <NavLeft className="nav-left">
                        <Menu mode="horizontal">
                            <MenuItem
                                key="0"
                                onClick={() =>
                                    dispatch(toggleNavAction(!navCollapsed))
                                }
                            >
                                {navCollapsed && (
                                    <MenuUnfoldOutlined className="nav-icon" />
                                )}

                                {!navCollapsed && (
                                    <MenuFoldOutlined className="nav-icon" />
                                )}
                            </MenuItem>
                            <MenuItem key="1">
                                <Select
                                    value={
                                        currentCountry
                                            ? `${currentCountry}`
                                            : undefined
                                    }
                                    style={{width: 180}}
                                    onChange={(value) =>
                                        handleSetCurrentCountry(Number(value))
                                    }
                                    suffixIcon={
                                        <DownOutlined
                                            style={{fontSize: '0.8rem'}}
                                        />
                                    }
                                    optionLabelProp="children"
                                    placeholder="Seleccione país"
                                    loading={loadingCountries}
                                    disabled={loadingCountries}
                                    className="country-select"
                                >
                                    {countries.map(({id, name}: ICountry) => (
                                        <Option
                                            key={`${id}`}
                                            value={`${id}`}
                                            className="country-select-option"
                                        >
                                            {name}
                                        </Option>
                                    ))}
                                </Select>
                            </MenuItem>
                        </Menu>
                    </NavLeft>

                    <NavRight className="nav-right">
                        <LanguageDropdown locale={locale} dispatch={dispatch} />

                        <ProfileDropdown user={currentUser} />
                    </NavRight>
                </Nav>
            </HeaderWrapper>
        </Header>
    );
};
