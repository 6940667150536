// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {RequestError, ReduxError} from 'types';

export const getCompaniesTransactionsReportErrorAction = (
    error: RequestError
): ReduxError<CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_ERROR> => ({
    type: CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_ERROR,
    payload: error,
});
