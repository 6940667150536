import {Reducer} from 'redux';
import {AuthActionTypes} from 'redux/action-types/auth';
import {CompaniesActionTypes} from 'redux/action-types/companies';
import {CompaniesActions} from 'redux/actions/companies';
// custom
import {ICompaniesState} from 'types/company-types';

const companiesInitialState: ICompaniesState = {
    data: [],
    detail: null,
    error: null,
    loading: false,
    loadingCompanyUpdate: false,
    loadingOwnerUpdate: false,
    loadingDetail: false,
    loadingKYCDetail: false,
    loadingKYCUpdate: false,
    loadingPOSUpdate: false,
    loadingAMLDetail: false,
    pagination: {
        page: 1,
        perPage: 20,
        total: 0,
    },
    KYCDetail: null,
    AMLDetail: null,
    transactions: {
        data: [],
        loading: false,
        pagination: {
            page: 1,
            perPage: 20,
            total: 0,
        },
    },
    deposits: {
        data: [],
        loading: false,
        pagination: {
            page: 1,
            perPage: 20,
            total: 0,
        },
    },
    loadingReport: {
        companies: false,
        transactions: false,
    },
    billingInformation: null,
    loadingBillingInformation: false,
    notes: {
        data: [],
        loading: false,
        pagination: {
            page: 1,
            perPage: 20,
            total: 0,
        },
    },
    tags: {
        data: [],
        loadingUpdate: false,
        loading: false,
    },
    loadingNewNote: false,
    related: {
        data: null,
        loading: false,
    },
    companyDocuments: undefined,
    loadingCompanyDocuments: false,
};

export const CompaniesReducer: Reducer<ICompaniesState, CompaniesActions> = (
    state = companiesInitialState,
    action
) => {
    switch (action.type) {
        case CompaniesActionTypes.GET_COMPANIES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case CompaniesActionTypes.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: {...state.pagination, ...action.payload.pagination},
                error: null,
                loading: false,
            };
        case CompaniesActionTypes.GET_COMPANIES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CompaniesActionTypes.GET_COMPANY_AML_REQUEST:
            return {
                ...state,
                error: null,
                loadingAMLDetail: true,
            };
        case CompaniesActionTypes.GET_COMPANY_AML_SUCCESS:
            return {
                ...state,
                AMLDetail: action.payload,
                error: null,
                loadingAMLDetail: false,
            };
        case CompaniesActionTypes.GET_COMPANY_AML_ERROR:
            return {
                ...state,
                error: action,
                loadingAMLDetail: false,
            };
        case CompaniesActionTypes.GET_COMPANY_REQUEST:
            return {
                ...state,
                error: null,
                loadingDetail: true,
            };
        case CompaniesActionTypes.GET_COMPANY_SUCCESS:
            return {
                ...state,
                detail: action.payload,
                error: null,
                loadingDetail: false,
            };
        case CompaniesActionTypes.GET_COMPANY_ERROR:
            return {
                ...state,
                error: action,
                loadingDetail: false,
            };
        case CompaniesActionTypes.GET_COMPANY_KYC_REQUEST:
            return {
                ...state,
                error: null,
                loadingKYCDetail: true,
            };
        case CompaniesActionTypes.GET_COMPANY_KYC_SUCCESS:
            return {
                ...state,
                KYCDetail: action.payload,
                error: null,
                loadingKYCDetail: false,
            };
        case CompaniesActionTypes.GET_COMPANY_KYC_ERROR:
            return {
                ...state,
                error: action,
                loadingKYCDetail: false,
            };
        case CompaniesActionTypes.GET_COMPANY_TRANSACTIONS_REQUEST:
            return {
                ...state,
                error: null,
                transactions: {
                    ...state.transactions,
                    loading: true,
                },
            };
        case CompaniesActionTypes.GET_COMPANY_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                error: null,
                transactions: {
                    ...state.transactions,
                    loading: false,
                    data: action.payload.data,
                    pagination: {
                        ...state.transactions.pagination,
                        ...action.payload.pagination,
                    },
                },
            };
        case CompaniesActionTypes.GET_COMPANY_TRANSACTIONS_ERROR:
            return {
                ...state,
                error: action,
                transactions: {
                    ...state.transactions,
                    loading: false,
                },
            };
        case CompaniesActionTypes.GET_COMPANY_DEPOSITS_REQUEST:
            return {
                ...state,
                error: null,
                deposits: {
                    ...state.deposits,
                    loading: true,
                },
            };
        case CompaniesActionTypes.GET_COMPANY_DEPOSITS_SUCCESS:
            return {
                ...state,
                error: null,
                deposits: {
                    ...state.deposits,
                    loading: false,
                    data: action.payload.data,
                    pagination: {
                        ...state.deposits.pagination,
                        ...action.payload.pagination,
                    },
                },
            };
        case CompaniesActionTypes.GET_COMPANY_DEPOSITS_ERROR:
            return {
                ...state,
                error: action,
                deposits: {
                    ...state.deposits,
                    loading: false,
                },
            };
        case CompaniesActionTypes.PATCH_COMPANY_STATUS_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case CompaniesActionTypes.PATCH_COMPANY_STATUS_SUCCESS:
            return {
                ...state,
                data: action.payload.companiesList,
                detail: action.payload.detail,
                error: null,
                loadingUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_STATUS_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case CompaniesActionTypes.GET_COMPANIES_REPORT_REQUEST:
            return {
                ...state,
                error: null,
                loadingReport: {
                    ...state.loadingReport,
                    companies: true,
                },
            };
        case CompaniesActionTypes.GET_COMPANIES_REPORT_SUCCESS:
            return {
                ...state,
                error: null,
                loadingReport: {
                    ...state.loadingReport,
                    companies: false,
                },
            };
        case CompaniesActionTypes.GET_COMPANIES_REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingReport: {
                    ...state.loadingReport,
                    companies: false,
                },
            };
        case CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_REQUEST:
            return {
                ...state,
                error: null,
                loadingReport: {
                    ...state.loadingReport,
                    transactions: true,
                },
            };
        case CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_SUCCESS:
            return {
                ...state,
                error: null,
                loadingReport: {
                    ...state.loadingReport,
                    transactions: false,
                },
            };
        case CompaniesActionTypes.GET_COMPANIES_TRANSACTIONS_REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingReport: {
                    ...state.loadingReport,
                    transactions: false,
                },
            };
        case CompaniesActionTypes.PATCH_COMPANY_REQUEST:
            return {
                ...state,
                error: null,
                loadingCompanyUpdate: true,
            };
        case CompaniesActionTypes.PATCH_COMPANY_SUCCESS:
            return {
                ...state,
                detail: action.payload,
                error: null,
                loadingCompanyUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingCompanyUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_OWNER_REQUEST:
            return {
                ...state,
                error: null,
                loadingOwnerUpdate: true,
            };
        case CompaniesActionTypes.PATCH_COMPANY_OWNER_SUCCESS:
            return {
                ...state,
                detail: action.payload,
                error: null,
                loadingOwnerUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_OWNER_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingOwnerUpdate: false,
            };
        case CompaniesActionTypes.GET_COMPANY_BILLING_INFORMATION_REQUEST:
            return {
                ...state,
                error: null,
                billingInformation: null,
                loadingBillingInformation: true,
            };
        case CompaniesActionTypes.GET_COMPANY_BILLING_INFORMATION_SUCCESS:
            return {
                ...state,
                billingInformation: action.payload,
                error: null,
                loadingBillingInformation: false,
            };
        case CompaniesActionTypes.GET_COMPANY_BILLING_INFORMATION_ERROR:
            return {
                ...state,
                error: action,
                loadingBillingInformation: false,
            };
        case CompaniesActionTypes.GET_COMPANY_NOTES_REQUEST:
            return {
                ...state,
                error: null,
                notes: {
                    ...state.notes,
                    loading: true,
                },
            };
        case CompaniesActionTypes.GET_COMPANY_NOTES_SUCCESS:
            return {
                ...state,
                error: null,
                notes: {
                    ...state.notes,
                    loading: false,
                    data: action.payload.data,
                    pagination: {
                        ...state.notes.pagination,
                        ...action.payload.pagination,
                    },
                },
            };
        case CompaniesActionTypes.GET_COMPANY_NOTES_ERROR:
            return {
                ...state,
                error: action,
                notes: {
                    ...state.notes,
                    loading: false,
                },
            };
        case CompaniesActionTypes.POST_COMPANY_NOTE_REQUEST:
            return {
                ...state,
                error: null,
                loadingNewNote: true,
            };
        case CompaniesActionTypes.POST_COMPANY_NOTE_SUCCESS:
            return {
                ...state,
                error: null,
                loadingNewNote: false,
            };
        case CompaniesActionTypes.POST_COMPANY_NOTE_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingNewNote: false,
            };
        case CompaniesActionTypes.GET_COMPANY_TAGS_REQUEST:
            return {
                ...state,
                error: null,
                tags: {
                    ...state.tags,
                    loading: true,
                },
            };
        case CompaniesActionTypes.GET_COMPANY_TAGS_SUCCESS:
            return {
                ...state,
                error: null,
                tags: {
                    ...state.tags,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case CompaniesActionTypes.GET_COMPANY_TAGS_ERROR:
            return {
                ...state,
                error: action,
                tags: {
                    ...state.tags,
                    loading: false,
                },
            };
        case CompaniesActionTypes.POST_COMPANY_TAGS_REQUEST:
            return {
                ...state,
                error: null,
                tags: {
                    ...state.tags,
                    loadingUpdate: true,
                },
            };
        case CompaniesActionTypes.POST_COMPANY_TAGS_SUCCESS:
            return {
                ...state,
                error: null,
                tags: {
                    ...state.tags,
                    loadingUpdate: false,
                },
            };
        case CompaniesActionTypes.POST_COMPANY_TAGS_ERROR:
            return {
                ...state,
                error: action.payload,
                tags: {
                    ...state.tags,
                    loadingUpdate: false,
                },
            };
        case CompaniesActionTypes.DELETE_COMPANY_KYC_REQUEST:
            return {
                ...state,
                error: null,
                loadingKYCUpdate: true,
            };
        case CompaniesActionTypes.DELETE_COMPANY_KYC_SUCCESS:
            return {
                ...state,
                error: null,
                loadingKYCUpdate: false,
            };
        case CompaniesActionTypes.DELETE_COMPANY_KYC_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingKYCUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_SETTINGS_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdate: true,
            };
        case CompaniesActionTypes.PATCH_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload.companiesList,
                detail: action.payload.detail,
                error: null,
                loadingUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingUpdate: false,
            };
        case CompaniesActionTypes.GET_RELATED_COMPANIES_REQUEST:
            return {
                ...state,
                error: null,
                related: {
                    ...state.related,
                    loading: true,
                },
            };
        case CompaniesActionTypes.GET_RELATED_COMPANIES_SUCCESS:
            return {
                ...state,
                error: null,
                related: {
                    ...state.related,
                    loading: false,
                    data: action.payload,
                },
            };
        case CompaniesActionTypes.GET_RELATED_COMPANIES_ERROR:
            return {
                ...state,
                error: action,
                related: {
                    ...state.related,
                    loading: false,
                },
            };
        case CompaniesActionTypes.PATCH_COMPANY_POS_STATUS_REQUEST:
            return {
                ...state,
                error: null,
                loadingPOSUpdate: true,
            };
        case CompaniesActionTypes.PATCH_COMPANY_POS_STATUS_SUCCESS:
            return {
                ...state,
                detail: action.payload.companyDetail,
                error: null,
                loadingPOSUpdate: false,
            };
        case CompaniesActionTypes.PATCH_COMPANY_POS_STATUS_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingPOSUpdate: false,
            };
        case CompaniesActionTypes.GET_COMPANY_DOCUMENTS_REQUEST:
            return {
                ...state,
                loadingCompanyDocuments: true,
            };
        case CompaniesActionTypes.GET_COMPANY_DOCUMENTS_SUCCESS:
            return {
                ...state,
                error: null,
                loadingCompanyDocuments: false,
                companyDocuments: action.payload,
            };
        case CompaniesActionTypes.GET_COMPANY_DOCUMENTS_ERROR:
            return {
                ...state,
                error: action,
                companyDocuments: undefined,
            };
        case AuthActionTypes.LOGOUT:
            return companiesInitialState;
        default:
            return state;
    }
};
