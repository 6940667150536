import {Action} from 'redux';

// custom
import {TagsActionTypes} from 'redux/action-types/tags';
import {ITag} from 'types/tag-types';

export interface IPostTagSuccessAction
    extends Action<TagsActionTypes.POST_TAG_SUCCESS> {
    payload: ITag;
}

export const postTagSuccessAction = (data: ITag): IPostTagSuccessAction => ({
    type: TagsActionTypes.POST_TAG_SUCCESS,
    payload: data,
});
