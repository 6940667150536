import { AxiosResponse } from 'axios';
import * as storage from 'localforage';
import get from 'lodash/get';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
// action types
import { AuthActionTypes } from 'redux/action-types/auth';
import {
  getCurrentUserErrorAction,
} from 'redux/actions/auth/getCurrentUserErrorAction';
// GET CURRENT USER
import {
  IGetCurrentUserRequestAction,
} from 'redux/actions/auth/getCurrentUserRequestAction';
// GET CURRENT USER
import {
  getCurrenUserSuccessAction,
} from 'redux/actions/auth/getCurrentUserSuccesstAction';
import { postLoginErrorAction } from 'redux/actions/auth/postLoginErrorAction';
// types
// LOGIN
import {
  IPostLoginRequestAction,
} from 'redux/actions/auth/postLoginRequestAction';
// actions
// LOGIN
import {
  IAuthData,
  postLoginSuccessAction,
} from 'redux/actions/auth/postLoginSuccessAction';
import {
  postRefreshTokenErrorAction,
} from 'redux/actions/auth/postRefreshTokenErrorAction';
// REFRESH TOKEN
import {
  IPostRefreshTokenRequestAction,
} from 'redux/actions/auth/postRefreshTokenRequestAction';
// REFRESH TOKEN
import {
  postRefreshTokenSuccessAction,
} from 'redux/actions/auth/postRefreshTokenSuccessAction';
// requests
import {
  getCurrentUserRequest,
  postLoginRequest,
  postRefreshTokenRequest,
} from 'services/auth';
import {
  IStoreState,
  RequestError,
} from 'types';
import { ICurrentUser } from 'types/auth-types';
// custom
import { parseErrors } from 'utils';

function* postLogin(action: IPostLoginRequestAction) {
    try {
        const result: AxiosResponse = yield call(
            postLoginRequest,
            action.payload
        );
        const data: IAuthData = {
            token: get(result.data, 'data.accessToken', ''),
            refresh: get(result.data, 'data.refreshToken', ''),
        };

        yield storage.setItem(
            'token',
            get(result.data, 'data.accessToken', '')
        );
        yield put(postLoginSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postLoginErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

function* postRefreshToken(action: IPostRefreshTokenRequestAction) {
    const {
        auth: {refresh},
    }: IStoreState = yield select();

    try {
        const result: AxiosResponse = yield call(
            postRefreshTokenRequest,
            refresh
        );
        const data: IAuthData = {
            token: get(result.data, 'data.accessToken', ''),
            refresh: get(result.data, 'data.refreshToken', ''),
        };

        yield storage.setItem(
            'token',
            get(result.data, 'data.accessToken', '')
        );
        yield put(postRefreshTokenSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(postRefreshTokenErrorAction(customError));

        window.location.href = '/signout';

        action.callback && action.callback(customError);
    }
}

function* getCurrentUser(action: IGetCurrentUserRequestAction) {
    try {
        const result: AxiosResponse = yield call(getCurrentUserRequest);
        const data: ICurrentUser = get(result.data, 'data', {});

        yield put(getCurrenUserSuccessAction(data));

        action.callback && action.callback(null, data);
    } catch (error) {
        const customError: RequestError = parseErrors(error);

        yield put(getCurrentUserErrorAction(customError));

        action.callback && action.callback(customError);
    }
}

export function* authSagas() {
    yield takeLatest(AuthActionTypes.POST_LOGIN_REQUEST, postLogin);
    yield takeLatest(
        AuthActionTypes.POST_REFRESH_TOKEN_REQUEST,
        postRefreshToken
    );
    yield takeLatest(AuthActionTypes.GET_CURRENT_USER_REQUEST, getCurrentUser);
}
