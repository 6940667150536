import React from 'react';

import {ConfigProvider} from 'antd';
// custom
import {locales} from 'assets/lang';
//* AUTH *//
import SignOut from 'pages/auth/sign-out';
// errors pages
import Error404 from 'pages/errors/404';
import Error500 from 'pages/errors/500';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import {getIsLogged} from 'redux/selectors/auth';
// selectors
import {getLocale} from 'redux/selectors/theme';
// types
import {
    CommonProps,
    IStoreState,
} from 'types';

// auth routes
import {AuthRoutes} from './AuthRoutes';
//* DASHBOARD *//
import DashboardRoutes from './Dashboard';
import PrivateRoute from './PrivateRoute';
import {PublicRoute} from './PublicRoute';

export type RouterProps = CommonProps & {
    locale: string;
    isLogged: boolean;
};

const Router: React.FC<RouterProps> = ({locale, isLogged}) => {
    const currentLocale = locales[locale.toLowerCase() as keyof typeof locales];
    return (
        <BrowserRouter>
            <IntlProvider
                locale={currentLocale.locale}
                messages={currentLocale.messages}
            >
                <ConfigProvider locale={currentLocale.antd}>
                    <Switch>
                        {/* LOGOUT */}
                        <Route exact path="/signout" component={SignOut} />
                        {/* ERRORS */}
                        <Route exact path="/404" component={Error404} />
                        <Route exact path="/500" component={Error500} />
                        <PublicRoute
                            path="/signin"
                            component={AuthRoutes}
                            isAuthenticated={isLogged}
                        />
                        <PublicRoute
                            path="/forgot-password"
                            component={AuthRoutes}
                            isAuthenticated={isLogged}
                        />
                        <PrivateRoute path="/" component={DashboardRoutes} />
                        <Redirect from="*" to="/404" push={false} />
                    </Switch>
                </ConfigProvider>
            </IntlProvider>
        </BrowserRouter>
    );
};

const mapStateToProps = (state: IStoreState) => ({
    isLogged: getIsLogged(state),
    locale: getLocale(state),
});

export default connect(mapStateToProps)(Router);
