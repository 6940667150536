import {Action} from 'redux';

// custom
import {UsersActionTypes} from 'redux/action-types/users';
import {IUser} from 'types/user-types';

export interface IPostUserSuccessAction
    extends Action<UsersActionTypes.POST_USER_SUCCESS> {
    payload: IUser;
}

export const postUserSuccessAction = (data: IUser): IPostUserSuccessAction => ({
    type: UsersActionTypes.POST_USER_SUCCESS,
    payload: data,
});
