import {WarningOutlined} from '@ant-design/icons';
export const BoundaryErrorMessage = () => {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <WarningOutlined style={{fontSize: '10rem', color: '#29B593'}} />
            <h1>Lo sentimos, ha ocurrido un error.</h1>
        </div>
    );
};
