import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IPostCompanyTagsSuccessAction
    extends Action<CompaniesActionTypes.POST_COMPANY_TAGS_SUCCESS> {}

export const postCompanyTagsSuccessAction =
    (): IPostCompanyTagsSuccessAction => ({
        type: CompaniesActionTypes.POST_COMPANY_TAGS_SUCCESS,
    });
