import {Spin} from 'antd';
import {useIntl} from 'react-intl';

//customs
import {Flex} from 'components/shared/Flex';

export const RefreshingScreen: React.FC = () => {
    const intl = useIntl();
    return (
        <Flex className="h-100vh" alignItems="center" justifyContent="center">
            <Spin
                size="large"
                tip={intl.formatMessage({id: 'common.pleaseWait.title'})}
            />
        </Flex>
    );
};
