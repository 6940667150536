import {Action} from 'redux';

// custom
import {CompaniesActionTypes} from 'redux/action-types/companies';

export interface IPatchCompanySettingsSuccessAction
    extends Action<CompaniesActionTypes.PATCH_COMPANY_SETTINGS_SUCCESS> {
    payload: any;
}

export const patchCompanySettingsSuccessAction = (
    data: any
): IPatchCompanySettingsSuccessAction => ({
    type: CompaniesActionTypes.PATCH_COMPANY_SETTINGS_SUCCESS,
    payload: data,
});
