import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';

export interface ILogoutAction extends Action<AuthActionTypes.LOGOUT> {}

export const logoutAction = (): ILogoutAction => ({
  type: AuthActionTypes.LOGOUT,
});
