import {
  IGetCountriesParams,
} from 'redux/actions/countries/getCountriesRequestAction';
import {
  PatchCountryParams,
} from 'redux/actions/countries/patchCountryRequestAction';
import {
  Methods,
  request,
  RequestOptions,
  withToken,
} from 'utils/request';

export const getCountriesRequest = async (params: IGetCountriesParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.GET,
        params,
    });

    return request('/countries', options);
};

export const patchCountryRequest = async ({
    id,
    ...data
}: PatchCountryParams) => {
    const options: RequestOptions = await withToken({
        method: Methods.PATCH,
        data,
    });

    return request(`/countries/${id}`, options);
};
