import {Action} from 'redux';

// custom
import {AuthActionTypes} from 'redux/action-types/auth';
import {RequestError} from 'types';

export interface IGetCurrentUserRequestAction
    extends Action<AuthActionTypes.GET_CURRENT_USER_REQUEST> {
    callback: (error?: RequestError | any, data?: unknown) => void;
}

export const getCurrentUserRequestAction = (
    callback: (error?: RequestError | any, data?: unknown) => void
): IGetCurrentUserRequestAction => ({
    type: AuthActionTypes.GET_CURRENT_USER_REQUEST,
    callback,
});
