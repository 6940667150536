import React, {useEffect} from 'react';

import {Layout, Menu} from 'antd';
// custom
import HeaderNav from 'components/layouts/HeaderNav';
import {RefreshingScreen} from 'components/screens/RefreshingScreen';
import {Scrollbars} from 'react-custom-scrollbars';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
// selectors
import {
    getCurrentUserCompaniesPermission,
    getCurrentUserContactCenterPermission,
    getCurrentUserDepositsPermission,
    getCurrentUserReportsPermission,
    getCurrentUserSettingsPermission,
    getCurrentUserTransactionsPermission,
    getIsLogged,
    getIsRefreshing,
} from 'redux/selectors/auth';
import {
    getCountriesData,
    getCurrentCountry,
    isLoadingCountries,
} from 'redux/selectors/countries';
import {isNavCollapse} from 'redux/selectors/theme';
//interfaces
import {CommonProps, IStoreState} from 'types';
import {ICountry} from 'types/country-types';
import {PermissionEnum} from 'types/settings-type';

import {
    BankOutlined,
    DollarOutlined,
    FileAddOutlined,
    GlobalOutlined,
    HomeOutlined,
    RiseOutlined,
    SettingOutlined,
    ShopOutlined,
    TagsOutlined,
    UserOutlined,
} from '@ant-design/icons';

import {AppLayout} from './styles';

type DashboardProps = RouteComponentProps &
    CommonProps & {
        isLogged: boolean;
        navCollapsed: boolean;
        isRefreshing: boolean;
        countries: ICountry[];
        currentCountry: number | null;
        loadingCountries: boolean;
        depositsPermissions: PermissionEnum | undefined;
        companiesPermissions: PermissionEnum | undefined;
        reportsPermissions: PermissionEnum | undefined;
        transactionsPremissions: PermissionEnum | undefined;
        settingsPermissions: PermissionEnum | undefined;
        contactCenterPermissions: PermissionEnum | undefined;
    };

const Sider = Layout.Sider;
const MenuItemGroup = Menu.ItemGroup;
const MenuItem = Menu.Item;
const Content = Layout.Content;
const {SubMenu} = Menu;

const Dashboard: React.FC<DashboardProps> = ({
    isLogged,
    history,
    location,
    children,
    navCollapsed,
    isRefreshing,
    loadingCountries,
    depositsPermissions,
    companiesPermissions,
    reportsPermissions,
    transactionsPremissions,
    settingsPermissions,
    contactCenterPermissions,
}) => {
    // const userHasNotContactCenterPermissions =
    //     contactCenterPermissions === PermissionEnum.None;

    useEffect(() => {
        if (!isLogged) {
            return history.replace({
                pathname: '/signin',
                state: {from: location},
            });
        }
    }, [isLogged, location, history]);

    const intl = useIntl();

    const menuItems = [
        {
            title: 'sidenav.dashboard.home.title',
            icon: <HomeOutlined />,
            path: '/',
            show: true,
        },
        {
            title: 'sidenav.dashboard.deposits.title',
            icon: <DollarOutlined />,
            path: '/deposits',
            show:
                depositsPermissions &&
                depositsPermissions !== PermissionEnum.None,
        },
        {
            title: 'sidenav.dashboard.companies.title',
            icon: <ShopOutlined />,
            path: '/companies',
            show:
                companiesPermissions &&
                companiesPermissions !== PermissionEnum.None,
        },
        {
            title: 'sidenav.dashboard.reports.title',
            icon: <FileAddOutlined />,
            path: '/reports',
            show:
                reportsPermissions &&
                reportsPermissions !== PermissionEnum.None,
        },
        {
            title: 'sidenav.dashboard.transactions.title',
            icon: <RiseOutlined />,
            path: '/transactions',
            show:
                transactionsPremissions &&
                transactionsPremissions !== PermissionEnum.None,
        },
        {
            title: 'sidenav.dashboard.settings.title',
            icon: <SettingOutlined />,
            show:
                settingsPermissions &&
                settingsPermissions !== PermissionEnum.None,
            submenu: [
                {
                    title: 'sidenav.dashboard.countries.title',
                    icon: <GlobalOutlined />,
                    path: '/settings/countries',
                },
                {
                    title: 'sidenav.dashboard.banks.title',
                    icon: <BankOutlined />,
                    path: '/settings/banks',
                },
                {
                    title: 'sidenav.dashboard.settings.tags.title',
                    icon: <TagsOutlined />,
                    path: '/settings/tags',
                },
                {
                    title: 'sidenav.dashboard.settings.tags.users',
                    icon: <UserOutlined />,
                    path: '/settings/users',
                },
            ],
        },
    ];

    if (isRefreshing) {
        return <RefreshingScreen />;
    }

    return (
        <Layout>
            <HeaderNav />
            <Layout className="app-container">
                <Sider
                    className="side-nav"
                    width={250}
                    collapsed={navCollapsed}
                >
                    <Scrollbars autoHide>
                        <Menu
                            mode="inline"
                            theme="light"
                            defaultSelectedKeys={[location.pathname]}
                        >
                            <MenuItemGroup
                                title={intl.formatMessage({
                                    id: 'sidenav.dashboard.title',
                                })}
                            >
                                {menuItems.map((item) => {
                                    if (!item.show) {
                                        return null;
                                    }
                                    if (item.submenu) {
                                        return (
                                            <SubMenu
                                                key="sub1"
                                                icon={item.icon}
                                                title={intl.formatMessage({
                                                    id: item.title,
                                                })}
                                            >
                                                {item.submenu.map(
                                                    (submenuItem) => (
                                                        <MenuItem
                                                            key={
                                                                submenuItem.path
                                                            }
                                                        >
                                                            {!!submenuItem.icon &&
                                                                submenuItem.icon}

                                                            <span>
                                                                <FormattedMessage
                                                                    id={
                                                                        submenuItem.title
                                                                    }
                                                                />
                                                            </span>

                                                            <NavLink
                                                                to={
                                                                    submenuItem.path
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </SubMenu>
                                        );
                                    }

                                    return (
                                        <MenuItem key={item.path}>
                                            {!!item.icon && item.icon}

                                            <span>
                                                <FormattedMessage
                                                    id={item.title}
                                                />
                                            </span>

                                            <NavLink to={item.path ?? ''} />
                                        </MenuItem>
                                    );
                                })}
                            </MenuItemGroup>
                        </Menu>
                    </Scrollbars>
                </Sider>

                <AppLayout className="app-layout" $navCollapsed={navCollapsed}>
                    <div className="app-content">
                        {loadingCountries ? (
                            <RefreshingScreen />
                        ) : (
                            <Content>{children}</Content>
                        )}
                    </div>
                </AppLayout>
            </Layout>
        </Layout>
    );
};

const mapStateToProps = (state: IStoreState) => ({
    isLogged: getIsLogged(state),
    navCollapsed: isNavCollapse(state),
    isRefreshing: getIsRefreshing(state),
    countries: getCountriesData(state),
    currentCountry: getCurrentCountry(state),
    loadingCountries: isLoadingCountries(state),
    depositsPermissions: getCurrentUserDepositsPermission(state),
    companiesPermissions: getCurrentUserCompaniesPermission(state),
    reportsPermissions: getCurrentUserReportsPermission(state),
    transactionsPremissions: getCurrentUserTransactionsPermission(state),
    settingsPermissions: getCurrentUserSettingsPermission(state),
    contactCenterPermissions: getCurrentUserContactCenterPermission(state),
});

export default withRouter(connect(mapStateToProps)(Dashboard));
