// custom
import {DepositsActionTypes} from 'redux/action-types/deposits';
import {RequestError, ReduxError} from 'types';

export const getDepositsByCountryErrorAction = (
  error: RequestError
): ReduxError<DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_ERROR> => ({
  type: DepositsActionTypes.GET_DEPOSITS_BY_COUNTRY_ERROR,
  payload: error,
});
