import React from 'react';
import classname from 'classnames';

export type FlexProps = {
  className?: string;
  mobileFlex?: boolean;
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around';
  flexDirection?: 'row' | 'row-reverse' | 'column' | ' column-reverse';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
};

export const Flex: React.FC<FlexProps> = ({
  children,
  className,
  mobileFlex = true,
  alignItems,
  flexDirection = 'row',
  justifyContent,
}) => (
  <div
    className={classname(className, {
      'd-flex': mobileFlex,
      'd-md-flex': !mobileFlex,
      [`align-items-${alignItems}`]: !!alignItems,
      [`flex-${flexDirection}`]: !!flexDirection,
      [`justify-content-${justifyContent}`]: !!justifyContent,
    })}
  >
    {children}
  </div>
);
