// custom
import {BanksActionTypes} from 'redux/action-types/banks';
import {RequestError, ReduxError} from 'types';

export const deleteBankByCountryErrorAction = (
  error: RequestError
): ReduxError<BanksActionTypes.DELETE_BANK_BY_COUNTRY_ERROR> => ({
  type: BanksActionTypes.DELETE_BANK_BY_COUNTRY_ERROR,
  payload: error,
});
